import React from 'react';
import { Route, Routes } from 'react-router-dom';

//COMPONENTS
import Protected from '../services/routes/protected';

//VIEWS
import Index from '../pages/home/sistema_gal';
import ProfileGal from '../pages/home/sistema_gal/profile_gal';

export default function SistemaGalRoutes(props) {
    return (
        <Routes>
            <Route
                index
                element={
                    <Protected>
                        <Index />
                    </Protected>
                }
            />
            <Route
                path={`view/:id`}
                element={
                    <Protected>
                        <ProfileGal />
                    </Protected>
                }
            />
            <Route path="*" element={<h1>Página não encontrada</h1>} />
        </Routes>
    );
}
