import axios from 'axios';
import { API_BASE_URL } from '../../util/constants';

class TabelaMotivoFinalizacao {
    listAll(data) {
        return axios.post(
            `${API_BASE_URL}/motivoFinalizar/selectMotivoFinalizar`,
            {},
            {
                headers: {
                    'access-token': localStorage.getItem('access-token')
                }
            }
        );
    }
    search(data) {
        return axios.post(
            `${API_BASE_URL}/motivoFinalizar/selectMotivoFinalizarByName`,
            {
                name: data.search
            },
            {
                headers: {
                    'access-token': localStorage.getItem('access-token')
                }
            }
        );
    }
    insert(data) {
        return axios.post(
            `${API_BASE_URL}/motivoFinalizar/insertMotivoFinalizar`,
            {
                value: data.value
            },
            {
                headers: {
                    'access-token': localStorage.getItem('access-token')
                }
            }
        );
    }
    update(data) {
        return axios.post(
            `${API_BASE_URL}/motivoFinalizar/editMotivoFinalizar`,
            {
                value: data.value,
                newValue: data.newValue
            },
            {
                headers: {
                    'access-token': localStorage.getItem('access-token')
                }
            }
        );
    }
    delete(data) {
        return axios.post(
            `${API_BASE_URL}/motivoFinalizar/deleteMotivoFinalizar`,
            {
                value: data.value
            },
            {
                headers: {
                    'access-token': localStorage.getItem('access-token')
                }
            }
        );
    }
}
export default new TabelaMotivoFinalizacao();
