import React from 'react';
import { Link } from 'react-router-dom';

//BOOTSTRAP REACT
import { Container, Row, Col } from 'react-bootstrap';
//CSS
//import '../../../../assets/css/pages/menus/tabelas-do-sistema/index.css';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartArea, faChartSimple } from '@fortawesome/free-solid-svg-icons';

export default function Index(props) {
    return (
        <Container fluid className="container_content_index">
            <Container fluid className="content_index full">
                <Col md className="teste">
                    <Row className="g-2 teste2">
                        <Link className="btn_index_tabelas_do_sistema" to={`contato-realizado`}>
                            <FontAwesomeIcon icon={faChartSimple} className="card_menu_icon" />
                            Contatos Relizados
                        </Link>
                        <Link className="btn_index_tabelas_do_sistema" to={`pacientes`}>
                            <FontAwesomeIcon icon={faChartArea} className="card_menu_icon" />
                            Pacientes
                        </Link>
                    </Row>
                </Col>
            </Container>
        </Container>
    );
}
