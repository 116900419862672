import React from 'react';
import { Container, Col } from 'react-bootstrap';
//import 'jquery/dist/jquery.min.js';
import Table from 'react-bootstrap/Table';

//CSS
import '../assets/css/components/customTable.css';

export default function CustomTable(props) {
    return (
        <Container fluid className="table_container">
            <Col className="table_col">
                <Table id={props.id} bordered hover className="table_custom">
                    <thead>
                        <tr>
                            <th></th>
                            {props.header}
                        </tr>
                    </thead>
                    <tbody>{props.data}</tbody>
                </Table>
            </Col>
        </Container>
    );
}
