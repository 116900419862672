import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

//BOOTSTRAP REACT
import { Container } from 'react-bootstrap';
import './style.css';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

//SERVICES
//import Logged from '../../../../../services/util/check';
import HttpService from '../../../../services/http/sistema_gal/sistema_gal_service';
import Toast from '../../../../services/util/toast';

export default function ProfileGal(props) {
    const { id } = useParams();
    const [data, setData] = useState('');

    useEffect(() => {
        getPaciente();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getPaciente() {
        if (data === '') {
            let dataReq = {
                id: id
            };
            const res = HttpService.getById(dataReq)
                .then((response) => {
                    setData(response.data.data[0]);
                    return true;
                })
                .catch((e) => {
                    Toast.error(e.response.data.message);
                    return false;
                });
            return res;
        }
    }

    return (
        <Container fluid className="container_content_index">
            <div className="container_content_index">
                <div className="header_tabela_gal">
                    <div className="d_arrow_back">
                        <button className="arrow_back">
                            <Link className="link" to="/sistema-gal">
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </Link>
                        </button>
                    </div>
                </div>

                <div className="container_tabela_gal_profile">
                    <div className="box_content">
                        <div className="col-md-11">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Código de amostra</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">{data.id}</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Requisição</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">{data.requisicao}</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Agravo da Requisicao</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">{data.agravo_da_requisicao}</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Resultado</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">{data.resultado}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="box_content">
                        <div className="col-md-11">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Unidade Solicitante</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">{data.unidade_solicitante}</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Data da Coleta</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">{data.data_da_coleta}</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Data da Solicitação</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">{data.data_da_solicitacao}</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Data da Liberação</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">{data.data_da_liberacao}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="box_content">
                        <div className="col-md-11">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Nome do Paciente</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">{data.paciente}</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Data de Nascimento</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">{data.data_de_nascimento}</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Idade</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {data.idade} {data.tipo_idade}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Sexo</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">{data.sexo}</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Telefone de Contato</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">{data.telefone_de_contato}</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Nome da Mãe</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">{data.nome_da_mae}</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Endereço</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {data.endereco} {data.bairro} - {data.municipio_de_residencia}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Exame</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">{data.exame}</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Data do 1º Sintomas</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">{data.data_do_1_sintomas}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
}
