import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faKey } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';

//CSS
import '../../assets/css/pages/login.css';

//BOOTSTRAP REACT
import { Container, Row, Col, Button } from 'react-bootstrap';

//SERVICES
import User from '../../services/util/check';
import HttpService from '../../services/http/auth/auth_service';
import Toast from '../../services/util/toast';
import { API_URL } from '../../services/util/constants';

class Login extends Component {
    constructor(props) {
        super(props);
        this.loginReq = this.loginReq.bind(this);
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onEnterState = this.onEnterState.bind(this);
        this.state = {
            username: '',
            password: ''
        };
    }

    componentDidMount() {
        $('#container_index').remove();

        User.user = false;
        User.token = '';

        let token = localStorage.getItem('access-token');

        if (token != null || token !== '') {
            var data = {
                token: token
            };
            HttpService.logout(data)
                .then((response) => {
                    localStorage.removeItem('access-token');
                    //User.checkLogged();
                })
                .catch((e) => {
                    localStorage.removeItem('access-token');
                });
        }
    }

    onChangeUsername(e) {
        this.setState({
            username: e.target.value
        });
    }

    onChangePassword(e) {
        this.setState({
            password: e.target.value
        });
    }

    async loginReq() {
        var data = {
            username: this.state.username,
            password: this.state.password
        };

        await HttpService.login(data)
            .then((response) => {
                if (response.data.status) {
                    localStorage.setItem('access-token', response.data.data.accessToken);
                    User.checkLogged();
                    window.location.href = API_URL + '/';
                    this.setState({ username: '' });
                    this.setState({ password: '' });
                }
            })
            .catch((e) => {
                //User.checkLogged();
                Toast.error(e.response.data.message);
            });
    }

    onEnterState(event) {
        if (event.charCode === 13) {
            this.setState(this.loginReq);
        }
    }

    render() {
        return (
            <>
                <Container fluid className="container_global">
                    <div className="background_login"></div>
                    <Row className="row_login">
                        <Col className="col_login">
                            <div className="container_header_login_form_title">
                                <div className="d_logo_login">
                                    <img className="logo-img-login" src={API_URL + '/assets/images/logo.png'} alt="" />
                                </div>

                                <div className="container_login_form_title_text">Acesso rápido</div>
                            </div>

                            <div className="container_login_form_inputs">
                                <div className="container_login_form_inputs_espace">
                                    <div className="container_login_form_inputs_input_icon">
                                        <FontAwesomeIcon icon={faUser} />
                                    </div>
                                    <input
                                        type="text"
                                        className="container_login_form_inputs_input"
                                        id="username"
                                        placeholder="Usuário"
                                        onChange={this.onChangeUsername}
                                        value={this.state.username}
                                        name="username"
                                        required
                                    />
                                </div>
                                <div className="container_login_form_inputs_espace">
                                    <div className="container_login_form_inputs_input_icon">
                                        <FontAwesomeIcon icon={faKey} />
                                    </div>
                                    <input
                                        type="password"
                                        className="container_login_form_inputs_input"
                                        id="password"
                                        placeholder="Senha"
                                        onChange={this.onChangePassword}
                                        value={this.state.password}
                                        name="password"
                                        required
                                        onKeyPress={this.onEnterState}
                                    />
                                </div>
                            </div>
                            <Button onClick={this.loginReq} className="container_login_form_btn">
                                Entrar
                            </Button>
                        </Col>
                        <img className="background_login_img" src="./assets/images/doctors.png" alt="" />
                    </Row>
                </Container>
            </>
        );
    }
}

export default Login;
