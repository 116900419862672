import React from 'react';
//BOOTSTRAP REACT
import { Container } from 'react-bootstrap';

export default function pageError() {
    return (
        <Container fluid className="container_content_index">
            <Container fluid className="content_index"></Container>
        </Container>
    );
}
