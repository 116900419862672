/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

//BOOTSTRAP REACT
import { Container, Form } from 'react-bootstrap';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';

//SERVICES
import HttpService from '../../../services/http/relatorios/relatorios_service';
import Toast from '../../../services/util/toast';
import { API_URL } from '../../../services/util/constants';

//COMPONENTS
// import useStateCallback from '../../../hooks/useStateCallback';
import pagination from '../../../components/pagination';
import customTable from '../../../components/customTable';
import Loading from '../../../components/loading';
import ExportExcel from '../../../components/export';
import Error from '../../errors/error';

export default function Relatorios(props) {
    // const id = props.id;
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    // const [showModal, setShowModal] = useState(false);
    const [pacientes_bloqueados, setPacientes_bloqueados] = useState([]);

    const [data, setData] = useState([]);
    const [header, setHeader] = useState([]);

    var hoje = new Date();
    hoje.setDate(hoje.getDate() - 90);
    var amanha = new Date();
    amanha.setDate(hoje.getDate() + 1);

    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [data1, setData1] = useState(hoje.toISOString().split('T')[0]);
    const [data2, setData2] = useState(amanha.toISOString().split('T')[0]);

    const [search, setSearch] = useState('');

    //REQUISIÇÂO DE DADOS
    async function getDataTable(verify, pagee, limite) {
        if (verify == null) {
            return false;
        }

        const page = parseInt(pagee);
        const limitee = parseInt(limite);

        let data = {
            page: page,
            limit: limitee
        };

        const result = await HttpService.consultaPacientes(data)
            .then((response) => {
                if (response.data.status) {
                    setHeader(response.data.header);
                    setData(response.data.data);
                    setCount(response.data.count);
                    setIsLoading(false);
                    return response.data.data;
                }
            })
            .catch((e) => {
                //setIsError(true);
                // setShowModal(false);
                setIsLoading(false);

                Toast.error('Nenhum resultado encontrado');
            });
        return result;
    }

    //SEARCH
    function onChangeSearch(e) {
        setCount(0);
        setPage(1);
        setSearch(e.target.value);
    }
    //PESQUISA QUANDO O USUARIO PRESSIONA ENTER
    function onEnterStateSearch(event) {
        if (event.key === 'Enter') {
            if (search !== '') {
                getDataTableSearch('OK');
                return true;
            } else {
                getDataTable(true, page, limit);
                return true;
            }
        }
    }
    //REQUISIÇÃO DE DADOS PARA A PESQUISA
    async function getDataTableSearch(type) {
        if (type == null) {
            return false;
        }
        let data = {
            name: search,
            page: page,
            limit: limit
        };

        await HttpService.searchPacientes(data)
            .then((response) => {
                setIsLoading(false);
                setCount(response.data.count);
                setData(response.data.data);
                setHeader(response.data.header);
                return { data: response.data.data, header: response.data.header };
            })
            .catch((e) => {
                //alert(e.response.data.message);
                return false;
            });
    }

    function onChangeDate1(value) {
        if (value === '') {
            return false;
        }
        setData1(value);
        // setData1(value);
        getDataTable(true, page, limit);
    }

    function onChangeDate2(value) {
        if (value === '') {
            return false;
        }
        setData2(value);
        // setData2(value);
        getDataTable(true, page, limit);
    }
    function changePagination(nPage) {
        if (nPage === '') {
            return false;
        }
        setPage(nPage);
        if (search !== '') {
            getDataTableSearch(true);
        } else {
            getDataTable(true, page, limit);
        }
    }
    function changeLimitPage(value) {
        if (value === '') {
            return false;
        }
        setLimit(value);
        if (search !== '') {
            getDataTableSearch(true);
        } else {
            getDataTable(true, page, limit);
        }
    }

    var Pagination = pagination;
    var CustomTable = customTable;

    useEffect(() => {
        getDataTable(true, page, limit);
        //onChangeSearch();
        //onEnterStateSearch('');
        onChangeDate1('');
        onChangeDate2('');
        changePagination('');
        changeLimitPage('');

        props.socket.on('pacientes_bloqueados', (data) => {
            setPacientes_bloqueados(data);
        });
        props.socket.emit('getPacientes_bloqueados', (data) => {
            setPacientes_bloqueados(data);
        });

        return function cleanup() {
            props.socket.off('pacientes_bloqueados');
        };

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Container fluid className="container_content_index">
                <Container fluid className="content_index">
                    <div className="header_index">
                        <ExportExcel
                            data={data}
                            header={header}
                            onlyPage={true}
                            service={async (limitPage, offSet) => {
                                const data = await getDataTable(true, offSet, limitPage);
                                return data;
                            }}
                        />
                        <input
                            className="search_input_tabela_gal"
                            type="text"
                            placeholder="Pesquisar"
                            onKeyPress={onEnterStateSearch}
                            onChange={onChangeSearch}
                            value={search}
                        />
                    </div>

                    {isError ? (
                        <Error />
                    ) : isLoading ? (
                        <Loading />
                    ) : (
                        <CustomTable
                            id="table-to-xls"
                            header={header.map((result) => {
                                return <th>{result.value}</th>;
                            })}
                            data={data.map((result) => {
                                let paciente_block = false;
                                let operador = '';

                                for (let i = 0; i < pacientes_bloqueados.length; i++) {
                                    if (result.id === parseInt(pacientes_bloqueados[i].id)) {
                                        operador = pacientes_bloqueados[i].operador;
                                        paciente_block = true;
                                    }
                                }
                                return (
                                    <tr
                                        style={
                                            paciente_block
                                                ? {
                                                      backgroundColor: '#000000',
                                                      border: '#000000',
                                                      color: '#ffffff'
                                                  }
                                                : {}
                                        }
                                    >
                                        {paciente_block ? (
                                            <td>{operador}</td>
                                        ) : (
                                            <td>
                                                <button className="botoesFuncoes">
                                                    <Link
                                                        className="view"
                                                        to={API_URL + `/primeiro-atendimento/view/${result.id}`}
                                                    >
                                                        <FontAwesomeIcon icon={faPencil} />
                                                    </Link>
                                                    <div
                                                        style={{
                                                            marginLeft: '5px'
                                                        }}
                                                    ></div>
                                                </button>
                                            </td>
                                        )}
                                        {header.map((resulty) => {
                                            return (
                                                <>
                                                    <td>{result[resulty.name]}</td>
                                                </>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        />
                    )}

                    <Pagination
                        className="pagination-bar"
                        currentPage={page}
                        totalCount={count}
                        pageSize={limit}
                        countItems={data.length}
                        onPageChange={(nPage) => changePagination(nPage)}
                        onLimitChange={(value) => changeLimitPage(value)}
                    />
                </Container>
            </Container>
        </>
    );
}
