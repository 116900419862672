import React, { useState } from 'react';
import $ from 'jquery';

// import axios from 'axios';
//import { CSVLink } from 'react-csv';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

//BOOTSTRAP REACT
import { Button, Modal, Form } from 'react-bootstrap';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

// import { API_BASE_URL } from '../services/util/constants';
// import Toast from '../services/util/toast';

const ExportExcel = (props) => {
    const { service, data, apenasPaginaAtul } = props;
    const [showModal, setShowModal] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [loaded, setLoaded] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [exportData, setExportData] = useState([data]);
    const [onlyPage, setOnlyPage] = useState(true);
    const [limit, setLimit] = useState(1000);
    const [offSet, setOffSet] = useState(1);

    //DADOS PARA SEREM EXPORTADOS EM EXCEL

    const gerar = async () => {
        //GET DADOS
        if (onlyPage) {
            setExportData(data);
        } else {
            setIsUploading(true);
            const getData = await service(limit, offSet);
            setExportData(getData);
            setIsUploading(false);
        }

        setLoaded(true);
    };
    /*
    const camelCase = (str) => {
        return str.substring(0, 1).toUpperCase() + str.substring(1);
    };
    const filterColumns = (data) => {
        // Get column names
        let headers = [];

        header.map((result) => {
            headers.push({ label: camelCase(result.value), key: result.name });
            return true;
        });

        return headers;
    }; */

    const checkRadio = (e) => {
        if (e.target.id === 'completo-check') {
            $('#pagina-atual-check').prop('checked', false);
            $('#completo-check').prop('checked', true);
            setOnlyPage(false);
        } else {
            $('#completo-check').prop('checked', false);
            $('#pagina-atual-check').prop('checked', true);
            setOnlyPage(true);
        }
    };

    function handleShow(event) {
        setShowModal(true);
        setLoaded(false);
    }
    function handleClose(event) {
        setShowModal(false);
    }

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Exportar
            </Button>

            <Modal show={showModal} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Exportar</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {loaded ? (
                        <div>
                            {/*   <CSVLink
                                data={exportData}
                                headers={filterColumns(exportData)}
                                filename={new Date().getTime() + '.csv'}
                            >
                                Baixar em CSV
                            </CSVLink>
 */}
                            <div>
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="download-table-xls-button"
                                    table="table-to-xls"
                                    filename={new Date().getTime()}
                                    sheet="tablexls"
                                    buttonText={
                                        <>
                                            <div>
                                                <FontAwesomeIcon icon={faDownload} /> Baixar Excel
                                            </div>
                                        </>
                                    }
                                />
                            </div>
                        </div>
                    ) : (
                        <>
                            <Form.Check
                                type="radio"
                                label={'Página atual'}
                                id={`pagina-atual-check`}
                                onClick={(e) => checkRadio(e)}
                            />
                            {apenasPaginaAtul ? (
                                ''
                            ) : (
                                <>
                                    <Form.Check
                                        type="radio"
                                        label={'Completo'}
                                        id={`completo-check`}
                                        onClick={(e) => checkRadio(e)}
                                    />
                                </>
                            )}

                            <div style={{ marginTop: '50px' }}></div>

                            {onlyPage ? (
                                ''
                            ) : (
                                <>
                                    <label>Offset:</label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Offset"
                                        onChange={(e) => setOffSet(e.target.value)}
                                        value={offSet}
                                    />
                                    <div style={{ marginTop: '20px' }}></div>
                                    <label>Limite:</label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Limite"
                                        onChange={(e) => setLimit(e.target.value)}
                                        value={limit}
                                    />
                                </>
                            )}

                            <div style={{ marginTop: '50px' }}></div>

                            <Button variant="primary" onClick={gerar} disabled={isUploading}>
                                {isUploading ? 'Processando...' : 'Gerar Excel'}
                            </Button>
                        </>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ExportExcel;
