import React from 'react';
import { Link } from 'react-router-dom';

//BOOTSTRAP REACT
import { Container, Row, Col } from 'react-bootstrap';
import './style.css';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHospital, faBedPulse, faMessage, faCircleXmark, faRectangleXmark } from '@fortawesome/free-solid-svg-icons';

export default function Index(props) {
    return (
        <Container fluid className="container_content_index">
            <Container fluid className="content_index full">
                <Col md className="teste">
                    <Row className="g-2 teste2">
                        <Link className="btn_index_tabelas_do_sistema" to={`hospital`}>
                            <FontAwesomeIcon icon={faHospital} className="card_menu_icon" />
                            Hospital
                        </Link>
                        <Link className="btn_index_tabelas_do_sistema" to={`leito-internamento`}>
                            <FontAwesomeIcon icon={faBedPulse} className="card_menu_icon" />
                            Leito de internamento
                        </Link>
                        <Link className="btn_index_tabelas_do_sistema" to={`mensagem-padronizada`}>
                            <FontAwesomeIcon icon={faMessage} className="card_menu_icon" />
                            Mensagem padronizada
                        </Link>
                        <Link className="btn_index_tabelas_do_sistema" to={`motivo-finalizacao`}>
                            <FontAwesomeIcon icon={faCircleXmark} size="lg" className="card_menu_icon" />
                            Motivo de finalização
                        </Link>
                        <Link className="btn_index_tabelas_do_sistema" to={`motivo-finalizacao-monitoramento`}>
                            <FontAwesomeIcon icon={faRectangleXmark} className="card_menu_icon" />
                            Motivo de finalização monitoramento
                        </Link>
                    </Row>
                </Col>
            </Container>
        </Container>
    );
}
