import React from 'react';
import { Route, Routes } from 'react-router-dom';

//COMPONENTS
import Protected from '../services/routes/protected';

//VIEWS
import Index from '../pages/home/tabelas_do_sistema';
import TabelaHospital from '../pages/home/tabelas_do_sistema/views/hospital';
import TabelaLeito from '../pages/home/tabelas_do_sistema/views/leito_internamento';
import TabelaMsgPadronizada from '../pages/home/tabelas_do_sistema/views/msg_padronizada';
import TabelaMotivoFinalizacao from '../pages/home/tabelas_do_sistema/views/motivo_finalizacao';
import TabelaMotivoFinalizacaoMonitoramento from '../pages/home/tabelas_do_sistema/views/motivo_finalizacao_monitoramento';

export default function TabelasDoSistema(props) {
    return (
        <Routes>
            <Route
                index
                element={
                    <Protected>
                        <Index />
                    </Protected>
                }
            />
            <Route
                path={`/hospital`}
                element={
                    <Protected>
                        <TabelaHospital />
                    </Protected>
                }
            />
            <Route
                path={`/leito-internamento`}
                element={
                    <Protected>
                        <TabelaLeito />
                    </Protected>
                }
            />
            <Route
                path={`/mensagem-padronizada`}
                element={
                    <Protected>
                        <TabelaMsgPadronizada />
                    </Protected>
                }
            />
            <Route
                path={`/motivo-finalizacao`}
                element={
                    <Protected>
                        <TabelaMotivoFinalizacao />
                    </Protected>
                }
            />
            <Route
                path={`/motivo-finalizacao-monitoramento`}
                element={
                    <Protected>
                        <TabelaMotivoFinalizacaoMonitoramento />
                    </Protected>
                }
            />
            <Route path="*" element={<h1>Página não encontrada</h1>} />
        </Routes>
    );
}
