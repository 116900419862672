import React, { Component } from 'react';
import '../../assets/css/components/footer.css';

class Footer extends Component {
    render() {
        return (
            <div className="container_footer">
                <div id="footer_menu_space" className="d_logo">
                    <div id="" className="footer_menu_space"></div>
                </div>
                <div className="d_footer">
                    <label className="t_footer">
                        © 2022 — Secretaria Municipal da Saúde (SMS) - Coordenação de Tecnologia da Informação
                    </label>
                </div>
            </div>
        );
    }
}

export default Footer;
