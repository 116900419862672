import React, { Component } from 'react';
import { Link } from 'react-router-dom';
//jquery
import $ from 'jquery';

//BOOTSTRAP REACT
import { Container } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUpload,
    faPhoneVolume,
    faLaptopMedical,
    faClipboardUser,
    faFilePen
} from '@fortawesome/free-solid-svg-icons';

import '../../assets/css/components/menu.css';
import { API_URL } from '../../services/util/constants';

class Menu extends Component {
    activeItem(e) {
        $('.btn_menu').removeClass('active');

        e.currentTarget.classList.toggle('active');

        // event.currentTarget.classList.add('bg-salmon');

        // event.currentTarget.classList.remove('bg-salmon');

        //$(e).addClass('active');
    }

    render() {
        return (
            <Container fluid id="d_Menu" className="d_Menu">
                {/*   <div className="btn_menu btn_menu_primeiro">
                    <Link className="menu_link" to="/">
                        <div className="icon_btn_menu">
                            <FontAwesomeIcon icon={faHome} className="" />
                        </div>
                        <label> Início </label>
                    </Link>
                </div> */}

                <div className="btn_menu" onClick={(e) => this.activeItem(e)}>
                    <Link className="menu_link" to={API_URL + '/primeiro-atendimento'}>
                        <div className="icon_btn_menu">
                            <FontAwesomeIcon icon={faClipboardUser} className="" />
                        </div>
                        <label style={{ cursor: 'pointer' }}>Primeiro Atendimento</label>
                    </Link>
                </div>

                <div className="btn_menu" onClick={(e) => this.activeItem(e)}>
                    <Link className="menu_link" to={API_URL + '/monitoramento'}>
                        <div className="icon_btn_menu">
                            <FontAwesomeIcon icon={faLaptopMedical} className="" />
                        </div>
                        <label style={{ cursor: 'pointer' }}>Monitoramento</label>
                    </Link>
                </div>

                <div className="btn_menu" onClick={(e) => this.activeItem(e)}>
                    <Link className="menu_link" to={API_URL + '/monitoramento_digitro'}>
                        <div className="icon_btn_menu">
                            <FontAwesomeIcon icon={faPhoneVolume} className="" />
                        </div>
                        <label style={{ cursor: 'pointer' }}> Digitro </label>
                    </Link>
                </div>

                <div className="btn_menu" onClick={(e) => this.activeItem(e)}>
                    <Link className="menu_link" to={API_URL + '/sistema-gal'}>
                        <div className="icon_btn_menu">
                            <FontAwesomeIcon icon={faUpload} className="" />
                        </div>
                        <label style={{ cursor: 'pointer' }}>Sistema GAL</label>
                    </Link>
                </div>

                <div className="btn_menu" onClick={(e) => this.activeItem(e)}>
                    <Link className="menu_link" to={API_URL + '/relatorios'}>
                        <div className="icon_btn_menu">
                            <FontAwesomeIcon icon={faFilePen} className="" />
                        </div>
                        <label style={{ cursor: 'pointer' }}>Relatórios</label>
                    </Link>
                </div>

                {/*    <div className="btn_menu" onClick={(e) => this.activeItem(e)}>
                    <Link className="menu_link" to={API_URL + '/tabelas-do-sistema'}>
                        <div className="icon_btn_menu">
                            <FontAwesomeIcon icon={faGear} className="" />
                        </div>
                        <label style={{ cursor: 'pointer' }}>Configurações</label>
                    </Link>
                </div> */}
            </Container>
        );
    }
}

export default Menu;
