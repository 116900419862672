import React, { Component } from 'react';

//BOOTSTRAP REACT
import { Container, Form } from 'react-bootstrap';

//CSS
//import '../../../../assets/css/pages/menus/tabelas-do-sistema/leito-internamento.css';

//ICONS
/* import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
 */
//COMPONENTS
import CustomTable from '../../../components/customTable';
import Pagination from '../../../components/pagination';
import Loading from '../../../components/loading';

//SERVICES
//import Logged from "../../../../../services/util/check";
import Toast from '../../../services/util/toast';
import HttpService from '../../../services/http/monitoramento_digitro/monitoramento_digitro_service';

class TabelaDigitro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isError: false,
            showModal: false,

            //DADOS
            header: [],
            data: [],

            //PAGINAÇÃO
            count: 0,
            page: 1,
            limit: 10,

            //FILTROS
            search: '',
            dateFilter: ''
        };

        //REQUEST
        this.getDataTable = this.getDataTable.bind(this);

        //DATE FILTER
        this.onChangeDateFilter = this.onChangeDateFilter.bind(this);
        this.onEnterStateDateFilter = this.onEnterStateDateFilter.bind(this);

        //SEARCH
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.onEnterStateSearch = this.onEnterStateSearch.bind(this);
        this.getDataTableSearch = this.getDataTableSearch.bind(this);
    }

    componentDidMount() {
        this.getDataTable(true);
    }

    //REQUISIÇÂO DE DADOS
    async getDataTable(verify) {
        if (verify == null) {
            return false;
        }
        let data = {
            page: this.state.page,
            limit: this.state.limit
        };

        await HttpService.consulta(data)
            .then((response) => {
                this.setState({
                    count: response.data.count,
                    data: response.data.data,
                    header: response.data.header,
                    isLoading: false
                });
            })
            .catch((e) => {
                this.setState({
                    isError: true,
                    showModal: false,
                    isLoading: false
                });
                Toast.error(e.response.data.message);
            });
    }

    onChangeSearch(e) {
        this.setState({
            count: 0,
            page: 1,
            search: e.target.value
        });
    }
    onChangeDateFilter(e) {
        this.setState({
            count: 0,
            page: 1,
            dateFilter: e.target.value
        });
    }

    //PESQUISA QUANDO O USUARIO PRESSIONA ENTER
    onEnterStateSearch(event) {
        if (event.key === 'Enter') {
            if (this.state.search !== '') {
                this.getDataTableSearch('OK');
                return true;
            } else {
                this.getDataTable('OK');
                return true;
            }
        }
    }
    //PESQUISA QUANDO O USUARIO PRESSIONA ENTER
    onEnterStateDateFilter(event) {
        this.setState({ dateFilter: event.target.value }, () => {
            if (this.state.dateFilter !== '') {
                this.getDataTableSearch('OK');
                return true;
            } else {
                this.getDataTable('OK');
                return true;
            }
        });
    }

    //REQUISIÇÃO DE DADOS PARA A PESQUISA
    async getDataTableSearch(type) {
        if (type == null) {
            return false;
        }

        if (this.state.dateFilter !== '') {
            let data = {
                name: this.state.dateFilter,
                page: this.state.page,
                limit: this.state.limit,
                col: 'data_insercao'
            };

            await HttpService.search(data)
                .then((response) => {
                    this.setState({
                        isLoading: false,
                        count: response.data.count,
                        data: response.data.data,
                        header: response.data.header
                    });
                })
                .catch((e) => {
                    Toast.error('Nenhum resultado encontrado');
                    this.setState({
                        isLoading: false,
                        count: 0,
                        data: [{}]
                    });
                });
            return true;
        } else {
            let data = {
                name: this.state.search,
                page: this.state.page,
                limit: this.state.limit,
                col: 'nome'
            };

            await HttpService.search(data)
                .then((response) => {
                    this.setState({
                        isLoading: false,
                        count: response.data.count,
                        data: response.data.data,
                        header: response.data.header
                    });
                })
                .catch((e) => {
                    //alert(e.response.data.message);
                });
            return true;
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
                <>
                    <Loading />
                </>
            );
        }

        return (
            <Container fluid className="container_content_index">
                <Container fluid className="content_index">
                    <div className="header_index">
                        {/* <button className="btn_tabela_gal" onClick={this.novoRegistro}>
                            <FontAwesomeIcon icon={faPlus} />
                            <label htmlFor=""> Novo</label>
                        </button> */}

                        <Form.Control
                            type="date"
                            placeholder="Data"
                            style={{
                                maxWidth: '150px',
                                minWidth: '100px'
                            }}
                            onChange={(e) => this.onEnterStateDateFilter(e)}
                            value={this.state.dateFilter}

                            /*    value={form.pac_data_liberacao_exame}
                                    onChange={(e) => changeValue(e.target.value, 'pac_data_liberacao_exame')}
                                    isInvalid={!!errors.pac_data_liberacao_exame} */
                        />

                        <input
                            className="search_input_tabela_gal"
                            type="text"
                            placeholder="Pesquisar"
                            onKeyPress={this.onEnterStateSearch}
                            onChange={this.onChangeSearch}
                            value={this.state.search}
                        />
                    </div>

                    <CustomTable
                        id="table-to-xls"
                        header={this.state.header.map((result) => {
                            return (
                                <>
                                    <th>{result.value}</th>
                                </>
                            );
                        })}
                        data={this.state.data.map((result) => {
                            return (
                                <>
                                    <tr>
                                        <td></td>

                                        {this.state.header.map((resulty) => {
                                            if (resulty.value === 'Data e Hora Inserção') {
                                                return (
                                                    <>
                                                        <td>
                                                            {new Date(result[resulty.name]).toLocaleString() !==
                                                            'Invalid Date'
                                                                ? new Date(result[resulty.name]).toLocaleString()
                                                                : ''}
                                                        </td>
                                                    </>
                                                );
                                            }
                                            if (resulty.value === 'Data de Nascimento') {
                                                return (
                                                    <>
                                                        <td>
                                                            {new Date(result[resulty.name]).toLocaleDateString() !==
                                                            'Invalid Date'
                                                                ? new Date(result[resulty.name]).toLocaleDateString()
                                                                : ''}
                                                        </td>
                                                    </>
                                                );
                                            }
                                            if (resulty.value === 'Data de entrada na origem') {
                                                return (
                                                    <>
                                                        <td>
                                                            {new Date(result[resulty.name]).toLocaleDateString() !==
                                                            'Invalid Date'
                                                                ? new Date(result[resulty.name]).toLocaleDateString()
                                                                : ''}
                                                        </td>
                                                    </>
                                                );
                                            }
                                            return (
                                                <>
                                                    <td>{result[resulty.name]}</td>
                                                </>
                                            );
                                        })}
                                    </tr>
                                </>
                            );
                        })}
                    />

                    <Pagination
                        className="pagination-bar"
                        currentPage={this.state.page}
                        totalCount={this.state.count}
                        pageSize={this.state.limit}
                        countItems={this.state.data.length}
                        onPageChange={(nPage) => {
                            this.setState({ page: nPage }, () => {
                                if (this.state.search !== '') {
                                    this.getDataTableSearch(true);
                                } else {
                                    this.getDataTable(true);
                                }
                            });
                        }}
                        onLimitChange={(value) => {
                            this.setState({ limit: value }, () => {
                                if (this.state.search !== '') {
                                    this.getDataTableSearch(true);
                                } else {
                                    this.getDataTable(true);
                                }
                            });
                        }}
                    />
                </Container>
            </Container>
        );
    }
}
export default TabelaDigitro;
