import React, { useState } from 'react';
import axios from 'axios';

//BOOTSTRAP REACT
import { Button, Modal } from 'react-bootstrap';
//import { CSVLink } from 'react-csv';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { API_BASE_URL } from '../services/util/constants';
import Toast from '../services/util/toast';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import CustomTable from './customTable';

const ImportExcel = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [data, setData] = useState({});
    const [responseData, setResponseData] = useState({});
    const [loaded, setLoaded] = useState(false);

    //const { function } = props;

    function handleShow(event) {
        setShowModal(true);
    }
    function handleClose(event) {
        setShowModal(false);
        setData({});
        setLoaded(false);
    }

    const onFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const onFileUpload = () => {
        setIsUploading(true);

        const formData = new FormData();

        formData.append('file', selectedFile, selectedFile.name);

        axios
            .post(`${API_BASE_URL}/gal/upload`, formData, {
                headers: {
                    'access-token': localStorage.getItem('access-token')
                }
            })
            .then((response) => {
                setIsUploading(false);
                let dadosExport = [];
                response.data.duplicate.map(function (item) {
                    dadosExport.push({
                        paciente: item.paciente,
                        data_de_nascimento: new Date(item.data_de_nascimento).toLocaleDateString(),
                        municipio_de_residencia: item.municipio_de_residencia
                    });
                    return true;
                });
                setData(dadosExport);
                setResponseData(response.data);
                Toast.sucess('Arquivo Enviado!');
                setLoaded(true);
            })
            .catch((e) => {
                setIsUploading(false);
                Toast.error(e.response.data.message);
            });
    };

    const fileData = () => {
        if (selectedFile) {
            return (
                <div>
                    <h2>Detalhes do arquivo:</h2>

                    <p>Nome: {selectedFile.name}</p>

                    <p>Tipo: {selectedFile.type}</p>

                    <p>Última modificação: {selectedFile.lastModifiedDate.toDateString()}</p>
                </div>
            );
        } else {
            return <></>;
        }
    };

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Importar
            </Button>
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Importar</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {loaded ? (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <div>
                                    {responseData.sucess.length + ' registros inseridos.'}
                                    <br></br>
                                    {responseData.duplicate.length + ' registros duplicados.'}
                                    <br></br>
                                    {responseData.errors.length + ' registros com falha.'}
                                    <br></br>
                                    <br></br>
                                    {/*    <CSVLink
                                        data={data}
                                        header={['Paciente', 'Data de Nascimento']}
                                        filename={new Date().getTime() + '.csv'}
                                    >
                                        BAIXAR EXCEL
                                    </CSVLink> */}
                                </div>
                            </div>
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="download-table-xls-button"
                                table="tabelaExport"
                                filename={new Date().getTime()}
                                sheet="tablexls"
                                buttonText={
                                    <>
                                        <div>
                                            <FontAwesomeIcon icon={faDownload} /> Baixar Excel
                                        </div>
                                    </>
                                }
                            />
                            <i>Atenção:</i> Não envie o mesmo documento mais de uma vez!
                            <br></br>
                            <br></br>
                            <CustomTable
                                id="tabelaExport"
                                header={
                                    <>
                                        <th>Paciente</th>
                                        <th>Data de Nascimento</th>
                                        <th>Cidade</th>
                                    </>
                                }
                                data={data.map((result) => {
                                    return (
                                        <tr>
                                            <td></td>
                                            <td>{result.paciente}</td>
                                            <td>{result.data_de_nascimento}</td>
                                            <td>{result.municipio_de_residencia}</td>
                                        </tr>
                                    );
                                })}
                            />
                        </>
                    ) : (
                        <>
                            <div className="anexo_import-file">
                                <input type="file" onChange={onFileChange} />
                            </div>
                            {fileData}
                        </>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    {loaded ? (
                        ''
                    ) : (
                        <>
                            <Button
                                variant="primary"
                                disabled={isUploading}
                                onClick={!isUploading ? onFileUpload : null}
                            >
                                {isUploading ? 'Enviando...' : 'Enviar'}
                            </Button>
                        </>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ImportExcel;
