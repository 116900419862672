module.exports = {
    transformaEmIdade(data) {
        const dataSplit = data.split('-');

        var ano_aniversario = parseInt(dataSplit[0]);
        var mes_aniversario = parseInt(dataSplit[1]);
        var dia_aniversario = parseInt(dataSplit[2]);

        var d = new Date();
        var ano_atual = d.getFullYear();
        var mes_atual = d.getMonth() + 1;
        var dia_atual = d.getDate();

        var quantos_anos = ano_atual - ano_aniversario;

        if (mes_atual <= mes_aniversario && dia_atual < dia_aniversario) {
            quantos_anos--;
        }

        return quantos_anos < 0 ? 0 : quantos_anos;
    }
};
