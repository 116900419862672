import axios from 'axios';
import { API_BASE_URL } from '../../util/constants';

class LeitoInternamento {
    listAllLeitos(data) {
        return axios.post(
            `${API_BASE_URL}/leito/selectLeito`,
            {},
            {
                headers: {
                    'access-token': localStorage.getItem('access-token')
                }
            }
        );
    }
    search(data) {
        return axios.post(
            `${API_BASE_URL}/leito/selectLeitoByName`,
            {
                name: data.search
            },
            {
                headers: {
                    'access-token': localStorage.getItem('access-token')
                }
            }
        );
    }
    insert(data) {
        return axios.post(
            `${API_BASE_URL}/leito/insertLeito`,
            {
                value: data.value
            },
            {
                headers: {
                    'access-token': localStorage.getItem('access-token')
                }
            }
        );
    }
    update(data) {
        return axios.post(
            `${API_BASE_URL}/leito/editLeito`,
            {
                value: data.value,
                newValue: data.newValue
            },
            {
                headers: {
                    'access-token': localStorage.getItem('access-token')
                }
            }
        );
    }
    delete(data) {
        return axios.post(
            `${API_BASE_URL}/leito/deleteLeito`,
            {
                value: data.value
            },
            {
                headers: {
                    'access-token': localStorage.getItem('access-token')
                }
            }
        );
    }
}
export default new LeitoInternamento();
