import React, { Component } from 'react';
import { Link } from 'react-router-dom';

//BOOTSTRAP REACT
import { Container, Form } from 'react-bootstrap';
//CSS
import './style.css';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

//SERVICES
//import Logged from '../../../services/util/check';
import HttpService from '../../../services/http/sistema_gal/sistema_gal_service';

//COMPONENTS
import CustomTable from '../../../components/customTable';
import ImportExcel from '../../../components/importExcel';
import ExportExcel from '../../../components/export';
import Pagination from '../../../components/pagination';
import Loading from '../../../components/loading';
import Error from '../../errors/error';
import Toast from '../../../services/util/toast';

class Tabela_Gal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isError: false,
            isUploading: false,
            showModal: false,

            //DADOS
            header: [],
            data: [],
            selectedFile: null,

            //PAGINAÇÃO
            count: 0,
            page: 1,
            limit: 10,

            //FILTROS
            resultadoNegativo: false,
            search: '',
            dateFilter: ''
        };
        this.getDataTable = this.getDataTable.bind(this);
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.onEnterStateSearch = this.onEnterStateSearch.bind(this);
        this.getDataTableSearch = this.getDataTableSearch.bind(this);
        this.changeTypeResult = this.changeTypeResult.bind(this);
        this.onEnterStateDateFilter = this.onEnterStateDateFilter.bind(this);
        this.customTabela = this.customTabela.bind(this);
    }

    componentDidMount() {
        //REQUISIÇÃO DE DADOS
        this.getDataTable(this.state.resultadoNegativo);
    }

    //REQUISIÇÂO DE DADOS
    async getDataTable(resultadoNegativo) {
        this.setState({
            isLoading: true
        });

        if (resultadoNegativo == null) {
            return false;
        }
        let data = {
            resultadoNegativo: resultadoNegativo,
            page: this.state.page,
            limit: this.state.limit
        };

        const result = await HttpService.consulta(data)
            .then((response) => {
                this.setState({
                    isLoading: false,
                    count: response.data.count,
                    data: response.data.data,
                    header: response.data.headers
                });
            })
            .catch((e) => {
                this.setState({
                    isLoading: false,
                    isError: true
                });
                Toast.error(e.response.data.message);
            });
        return result;
    }

    //SEARCH
    onChangeSearch(e) {
        this.setState({
            count: 0,
            page: 1,
            search: e.target.value
        });
    }
    //PESQUISA QUANDO O USUARIO PRESSIONA ENTER
    onEnterStateSearch(event) {
        if (event.key === 'Enter') {
            if (this.state.search !== '') {
                this.getDataTableSearch('OK', this.state.page, this.state.limit);
                return true;
            } else {
                this.getDataTable(this.state.resultadoNegativo);
                return true;
            }
        }
    }
    //REQUISIÇÃO DE DADOS PARA A PESQUISA
    async getDataTableSearch(type, pageN, offsetN) {
        if (type == null) {
            return false;
        }

        if (this.state.dateFilter !== '') {
            let data = {
                value: this.state.dateFilter,
                page: pageN,
                limit: offsetN,
                type: 'data_da_liberacao'
            };

            const result = await HttpService.filter(data)
                .then((response) => {
                    this.setState({
                        isLoading: false,
                        count: response.data.count,
                        data: response.data.data,
                        header: response.data.header
                    });
                    return response.data.data;
                })
                .catch((e) => {
                    Toast.error('Nenhum resultado encontrado');
                    this.setState({
                        isLoading: false,
                        count: 0,
                        data: [{}]
                    });
                });
            return result;
        } else {
            let data = {
                search: this.state.search,
                page: this.state.page,
                limit: this.state.limit
            };

            const result = await HttpService.search(data)
                .then((response) => {
                    this.setState({
                        isLoading: false,
                        count: response.data.count,
                        data: response.data.data,
                        header: response.data.header
                    });

                    return response.data.data;
                })
                .catch((e) => {
                    //Toast.error(e.response.data.message);
                });
            return result;
        }
    }

    //ORDENAR PELO RESULTADO
    async changeTypeResult() {
        if (this.state.resultadoNegativo === false) {
            this.setState({
                isLoading: true,
                count: 0,
                page: 1,
                resultadoNegativo: true
            });
            await this.getDataTable(null);
        } else {
            this.setState({
                isLoading: true,
                count: 0,
                page: 1,
                resultadoNegativo: false
            });
            await this.getDataTable(null);
        }
        await this.getDataTable(this.state.resultadoNegativo);
        this.forceUpdate();
    }

    //PESQUISA QUANDO O USUARIO PRESSIONA ENTER
    onEnterStateDateFilter(event) {
        this.setState({ dateFilter: event.target.value, limit: 10000, page: 1 }, () => {
            if (this.state.dateFilter !== '') {
                this.getDataTableSearch(true, this.state.page, this.state.limit);
                return true;
            } else {
                this.setState({ dateFilter: event.target.value, limit: 10, page: 1 }, () => {
                    this.getDataTable('OK');
                });
                return true;
            }
        });
    }

    customTabela() {
        return (
            <CustomTable
                id="table-to-xls"
                header={this.state.header.map((result) => {
                    if (result.value === 'Resultado') {
                        return (
                            <>
                                <th onClick={this.changeTypeResult}>{result.value}</th>
                            </>
                        );
                    } else {
                        return (
                            <>
                                <th>{result.value}</th>
                            </>
                        );
                    }
                })}
                data={this.state.data.map((result) => {
                    return (
                        <>
                            <tr>
                                <td>
                                    <button className="botoesFuncoes">
                                        <Link className="view" to={`view/${result.id}`}>
                                            <FontAwesomeIcon icon={faEye} />
                                        </Link>
                                    </button>
                                </td>
                                {this.state.header.map((resulty) => {
                                    return (
                                        <>
                                            <td>{result[resulty.name]}</td>
                                        </>
                                    );
                                })}
                            </tr>
                        </>
                    );
                })}
            />
        );
    }

    render() {
        const Tabela = this.customTabela;
        if (this.state.isError) {
            return <Error />;
        }
        return (
            <Container fluid className="container_content_index">
                <Container fluid className="content_index">
                    <div className="header_index">
                        <ImportExcel></ImportExcel>
                        <div
                            style={{
                                marginLeft: '10px'
                            }}
                        ></div>
                        {this.state.dateFilter ? (
                            <>
                                <ExportExcel
                                    data={this.state.data}
                                    header={this.state.header}
                                    onlyPage={true}
                                    apenasPaginaAtul={true}
                                    tabela={async () => this.customTabela}
                                    service={async (limitPage, offSet) => {
                                        var data = null;
                                        if (this.state.dateFilter !== '') {
                                            data = await this.getDataTableSearch(true, limitPage, offSet);
                                            return data;
                                        }
                                    }}
                                />
                            </>
                        ) : (
                            ''
                        )}
                        <div
                            style={{
                                marginLeft: '10px'
                            }}
                        ></div>
                        <Form.Control
                            type="date"
                            placeholder="Data"
                            style={{
                                maxWidth: '150px',
                                minWidth: '100px'
                            }}
                            onChange={(e) => this.onEnterStateDateFilter(e)}
                            value={this.state.dateFilter}

                            /*    value={form.pac_data_liberacao_exame}
                                    onChange={(e) => changeValue(e.target.value, 'pac_data_liberacao_exame')}
                                    isInvalid={!!errors.pac_data_liberacao_exame} */
                        />

                        {/* <input
                            className="search_input_tabela_gal"
                            type="text"
                            placeholder="Pesquisar"
                            onKeyPress={this.onEnterStateSearch}
                            onChange={this.onChangeSearch}
                            value={this.state.search}
                        /> */}
                    </div>
                    {this.state.isError ? <Error /> : this.state.isLoading ? <Loading /> : <Tabela />}

                    <Pagination
                        className="pagination-bar"
                        currentPage={this.state.page}
                        totalCount={this.state.count}
                        pageSize={this.state.limit}
                        countItems={this.state.data.length}
                        onPageChange={(nPage) => {
                            this.setState({ page: nPage }, () => {
                                if (this.state.search !== '') {
                                    this.getDataTableSearch(true);
                                } else {
                                    this.getDataTable(true);
                                }
                            });
                        }}
                        onLimitChange={(value) => {
                            this.setState({ limit: value }, () => {
                                if (this.state.search !== '') {
                                    this.getDataTableSearch(true);
                                } else {
                                    this.getDataTable(true);
                                }
                            });
                        }}
                    />
                </Container>
            </Container>
        );
    }
}
export default Tabela_Gal;
