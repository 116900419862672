import React from 'react';
import { Outlet } from 'react-router-dom';

//CSS
import '../../assets/css/pages/index.css';

//BOOTSTRAP REACT
import { Container, Row, Col } from 'react-bootstrap';

//COMPONENTS
import Menu from '../../components/default/menu';
import Header from '../../components/default/header';
import Content from '../../components/default/content';
import Footer from '../../components/default/footer';

export default function Index() {
    return (
        <Container fluid id="container_index" className="container_global">
            <Col className="col_index">
                <Header />
                <Row className="row_index">
                    <Menu />
                    <Content>
                        <Outlet />
                    </Content>
                </Row>
                <Footer />
            </Col>
        </Container>
    );
}
