import axios from 'axios';
import { API_BASE_URL } from '../../util/constants';

class MonitoramentoDigitroService {
    consulta(data, token) {
        return axios.post(`${API_BASE_URL}/monitoramentoDigitro/selectMonitoramentoDigitro`, data, {
            headers: {
                'access-token': localStorage.getItem('access-token')
            }
        });
    }
    search(data, token) {
        return axios.post(`${API_BASE_URL}/monitoramentoDigitro/selectMonitoramentoDigitroByFilter`, data, {
            headers: {
                'access-token': localStorage.getItem('access-token')
            }
        });
    }
    filterByDate(data) {
        return axios.post(`${API_BASE_URL}/monitoramentoDigitro/selectMonitoramentoDigitroByFilter`, data, {
            headers: {
                'access-token': localStorage.getItem('access-token')
            }
        });
    }
    /*logout(data){
        return axios.post(`${API_BASE_URL}/auth/logout`, null, {
            headers: {"access-token" : localStorage.getItem('access-token')}
        });
    }
    check_token_valid(data){
        return axios.post(`${API_BASE_URL}/auth/checkToken`, null, {
            headers: {"access-token" : localStorage.getItem('access-token')}
        });
    }*/
}
export default new MonitoramentoDigitroService();
