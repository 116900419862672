import axios from 'axios';
import { API_BASE_URL } from '../../util/constants';

class TabelaMsgPadronizada {
    listAll(data) {
        return axios.post(
            `${API_BASE_URL}/mensagemPadrao/selectMensagemPadrao`,
            {},
            {
                headers: {
                    'access-token': localStorage.getItem('access-token')
                }
            }
        );
    }
    search(data) {
        return axios.post(
            `${API_BASE_URL}/mensagemPadrao/selectMensagemPadraoByName`,
            {
                name: data.search
            },
            {
                headers: {
                    'access-token': localStorage.getItem('access-token')
                }
            }
        );
    }
    insert(data) {
        return axios.post(
            `${API_BASE_URL}/mensagemPadrao/insertMensagemPadrao`,
            {
                value: data.value
            },
            {
                headers: {
                    'access-token': localStorage.getItem('access-token')
                }
            }
        );
    }
    update(data) {
        return axios.post(
            `${API_BASE_URL}/mensagemPadrao/editMensagemPadrao`,
            {
                value: data.value,
                newValue: data.newValue
            },
            {
                headers: {
                    'access-token': localStorage.getItem('access-token')
                }
            }
        );
    }
    delete(data) {
        return axios.post(
            `${API_BASE_URL}/mensagemPadrao/deleteMensagemPadrao`,
            {
                value: data.value
            },
            {
                headers: {
                    'access-token': localStorage.getItem('access-token')
                }
            }
        );
    }
}
export default new TabelaMsgPadronizada();
