import React, { useState } from 'react';
import axios from 'axios';

//BOOTSTRAP REACT
import { Button, Modal } from 'react-bootstrap';

import { API_BASE_URL } from '../../../../../services/util/constants';
import Toast from '../../../../../services/util/toast';
import CustomTable from '../../../../../components/customTable';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const ImportarCadastros = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [data, setData] = useState({});

    const [loaded, setLoaded] = useState(false);

    function handleShow(event) {
        setShowModal(true);
    }
    function handleClose(event) {
        setShowModal(false);
        setLoaded(false);
    }

    const onFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const onFileUpload = () => {
        setIsUploading(true);

        const formData = new FormData();

        formData.append('file', selectedFile, selectedFile.name);

        axios
            .post(`${API_BASE_URL}/cadastroSimplificado/upload`, formData, {
                headers: {
                    'access-token': localStorage.getItem('access-token')
                }
            })
            .then((response) => {
                setIsUploading(false);
                setIsUploading(false);
                let dadosExport = [];
                response.data.errors.map(function (item) {
                    dadosExport.push({
                        pac_nome: item.pac_nome,
                        pac_dt_nasc: new Date(item.pac_dt_nasc).toLocaleDateString(),
                        pac_mae: item.pac_mae
                    });
                    return true;
                });
                setData(dadosExport);
                Toast.sucess('Arquivo Enviado!');
                setLoaded(true);
            })
            .catch((e) => {
                setIsUploading(false);
                Toast.error(e.response.data.message);
            });
    };

    const fileData = () => {
        if (selectedFile) {
            return (
                <div>
                    <h2>Detalhes do arquivo:</h2>

                    <p>Nome: {selectedFile.name}</p>

                    <p>Tipo: {selectedFile.type}</p>

                    <p>Última modificação: {selectedFile.lastModifiedDate.toDateString()}</p>
                </div>
            );
        } else {
            return <></>;
        }
    };

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Importar
            </Button>
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Importar</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {loaded ? (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <div></div>
                            </div>
                            <ReactHtmlTableToExcel
                                id="test-table-xls-button"
                                className="download-table-xls-button"
                                table="tabelaExport"
                                filename={new Date().getTime()}
                                sheet="tablexls"
                                buttonText={
                                    <>
                                        <div>
                                            <FontAwesomeIcon icon={faDownload} /> Baixar Excel
                                        </div>
                                    </>
                                }
                            />
                            <i>Atenção:</i> Não envie o mesmo documento mais de uma vez!
                            <br></br>
                            <br></br>
                            <CustomTable
                                id="tabelaExport"
                                header={
                                    <>
                                        <th>Paciente</th>
                                        <th>Data de Nascimento</th>
                                        <th>Nome da Mãe</th>
                                    </>
                                }
                                data={data.map((result) => {
                                    return (
                                        <tr>
                                            <td></td>
                                            <td>{result.pac_nome}</td>
                                            <td>{result.pac_dt_nasc}</td>
                                            <td>{result.pac_mae}</td>
                                        </tr>
                                    );
                                })}
                            />
                        </>
                    ) : (
                        <>
                            <div className="anexo_import-file">
                                <input type="file" onChange={onFileChange} />
                            </div>
                            {fileData}
                        </>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    {loaded ? (
                        ''
                    ) : (
                        <>
                            <Button
                                variant="primary"
                                disabled={isUploading}
                                onClick={!isUploading ? onFileUpload : null}
                            >
                                {isUploading ? 'Enviando...' : 'Enviar'}
                            </Button>
                        </>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ImportarCadastros;
