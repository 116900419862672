import React, { Component } from 'react';
import $ from 'jquery';

//BOOTSTRAP REACT
import { Dropdown } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faRightToBracket, faUser } from '@fortawesome/free-solid-svg-icons';

import HttpService from '../../services/http/auth/auth_service';
import Logged from '../../services/util/check';

import '../../assets/css/components/header.css';
import { API_URL } from '../../services/util/constants';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: ''
        };
        this.logoutReq = this.logoutReq.bind(this);
        this.changeDropdown = this.changeDropdown.bind(this);
    }

    componentDidMount() {
        const name = Logged.updateName();
        this.setState({
            name: name
        });
    }

    logoutReq() {
        var data = {
            token: Logged.token
        };

        HttpService.logout(data)
            .then((response) => {
                localStorage.removeItem('access-token');
                Logged.checkLogged();
            })
            .catch((e) => {
                localStorage.removeItem('access-token');
                Logged.checkLogged();
                //alert('Erro ao deslogar: ' + e);
            });
    }

    changeDropdown(e) {
        // console.log(e);
    }

    handleClick = (e) => {
        $('#btn_header').on('click', function () {
            $('#d_Menu').hide();
            $('#d_logo').hide();
            $('#footer_menu_space').hide();

            //CHANGE WIDTH OF MENU
            $('#container_content').css('width', '100%');
            $(this).addClass('btn_header active');
        });

        $('#btn_header.active').on('click', function () {
            $('#d_Menu').show();
            $('#d_logo').show();
            $('#footer_menu_space').show();

            $('#container_content').css('width', 'calc(100% - var(--size-menu-horizontal))');
            $(this).removeClass('active');
        });
    };

    render() {
        return (
            <div className="container_header">
                <div id="d_logo" className="d_logo">
                    <a
                        href={API_URL + '/'}
                        style={{
                            textDecoration: 'none',
                            backgroundColor: 'white',
                            height: '100%',
                            width: '100%'
                        }}
                    >
                        <img className="logo-img" src={API_URL + '/assets/images/logo.png'} alt="" />
                    </a>
                </div>
                <button id="btn_header" className="btn_header" onClick={this.handleClick}>
                    <FontAwesomeIcon icon={faBars} />
                </button>
                <div className="t_title">MONITOR COVID</div>
                <div className="d_btn_login">
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-custom-components">
                            {this.state.name}
                            {'   '}
                            <FontAwesomeIcon icon={faUser} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item eventKey="1" onClick={this.logoutReq}>
                                <FontAwesomeIcon icon={faRightToBracket} /> Sair
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        );
    }
}

export default Header;
