import axios from 'axios';
import { API_BASE_URL } from '../../util/constants';

class TabelaHospital {
    listAll(data) {
        return axios.post(
            `${API_BASE_URL}/hospital/selectHospital`,
            {},
            {
                headers: {
                    'access-token': localStorage.getItem('access-token')
                }
            }
        );
    }
    search(data) {
        return axios.post(
            `${API_BASE_URL}/hospital/selectHospitalByName`,
            {
                name: data.search
            },
            {
                headers: {
                    'access-token': localStorage.getItem('access-token')
                }
            }
        );
    }
    insert(data) {
        return axios.post(
            `${API_BASE_URL}/hospital/insertHospital`,
            {
                value: data.value
            },
            {
                headers: {
                    'access-token': localStorage.getItem('access-token')
                }
            }
        );
    }
    update(data) {
        return axios.post(
            `${API_BASE_URL}/hospital/editHospital`,
            {
                value: data.value,
                newValue: data.newValue
            },
            {
                headers: {
                    'access-token': localStorage.getItem('access-token')
                }
            }
        );
    }
    delete(data) {
        return axios.post(
            `${API_BASE_URL}/hospital/deleteHospital`,
            {
                value: data.value
            },
            {
                headers: {
                    'access-token': localStorage.getItem('access-token')
                }
            }
        );
    }
}
export default new TabelaHospital();
