import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

//BOOTSTRAP REACT
import { Container, Form, Row, Col, FloatingLabel, Button } from 'react-bootstrap';

//COMPONENTS
import ContatosRealizados from '../../primeiro_atendimento/form_primeiro_atendimento/contatos_realizados';
import Monitorar from './monitorar';
import Historico from './historico_monitoramento';
import Loading from '../../../../../components/loading';
import Error from '../../../../errors/error';

//SERVICES
import HttpService from '../../../../../services/http/casos_confirmados/primeiro_atendimento_service';
import Logged from '../../../../../services/util/check';
import { API_URL } from '../../../../../services/util/constants';

export default function ViewPrimeiroAtendimento(props) {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [form, setForm] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        getPaciente();
        const name = Logged.updateName();
        props.socket.emit('bloqueia_paciente', { id: id, login: name });
        return () => {
            props.socket.emit('desbloqueia_paciente', id);
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //REQUISICAO DE DADOS
    async function getPaciente() {
        setIsLoading(true);
        setIsError(false);
        try {
            await HttpService.getById({
                id: id
            })
                .then(async (response) => {
                    setForm(response.data.data[0]);
                    setForm({
                        ...response.data.data[0],
                        pac_data_liberacao_exame: await formatDateIso(response.data.data[0].pac_data_liberacao_exame),
                        pac_dt_nasc: await formatDateIso(response.data.data[0].pac_dt_nasc),
                        pac_data_ini_sintoma: await formatDateIso(response.data.data[0].pac_data_ini_sintoma)
                    });
                    setIsLoading(false);
                })
                .catch((e) => {
                    setIsLoading(false);
                });
        } catch (error) {
            setIsLoading(false);
        }
    }

    async function formatDateIso(datee) {
        if (datee === '' || datee == null) {
            return '';
        }
        const date = new Date(datee);
        let day = date.getDate() + 1;
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        if (day < 10) {
            day = `0${day}`;
        }
        if (month < 10) {
            month = `0${month}`;
        }
        return `${year}-${month}-${day}`;
    }

    //MUDAR VALORES DO FORMULÁRIO
    const changeValue = (event, key) => {
        const value = event;
        const name = key;
        const obj = JSON.parse(`{"${name}": "${value}"}`);
        setForm({ ...form, ...obj });
    };

    if (isError) {
        return <Error />;
    }
    if (isLoading) {
        return <Loading />;
    }
    return (
        <Container fluid className="container_content_index">
            <Container fluid className="content_index primeiroAtendimentoFlex">
                <div
                    style={{
                        display: 'flex',
                        position: 'relative',
                        flexDirection: 'row'
                    }}
                >
                    <ContatosRealizados id={id} howButton={true} />
                    <div
                        style={{
                            marginLeft: 10
                        }}
                    ></div>
                    <Historico id={id} howButton={true} />
                    <div
                        style={{
                            marginLeft: 10
                        }}
                    ></div>
                    <Monitorar id={id} howButton={true} />
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,

                            justifyContent: 'right'
                        }}
                    >
                        <Button
                            variant="primary"
                            onClick={() => {
                                navigate(API_URL + '/primeiro-atendimento/view/' + id);
                            }}
                        >
                            Editar
                        </Button>
                    </div>
                </div>

                <div
                    style={{
                        marginTop: 15
                    }}
                ></div>
                <Form>
                    <div className="card mb-3">
                        <div className="card-body">
                            <Row>
                                <Col md>
                                    <div>Confirmado COVID-19</div>
                                    <Form.Control type="text" placeholder="" value={form.pac_confirmado} readOnly />
                                </Col>

                                <Col md>
                                    <FloatingLabel label="Data da liberação do exame" className="mb-3">
                                        <Form.Control
                                            type="date"
                                            placeholder="Data da liberação do exame"
                                            value={form.pac_data_liberacao_exame}
                                            onChange={(e) => changeValue(e.target.value, 'pac_data_liberacao_exame')}
                                            readOnly
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <div className="mb-0">Primeiros sintomas /Coleta/Último contato</div>
                                    <Form.Control
                                        type="date"
                                        placeholder=""
                                        value={form.pac_data_ini_sintoma}
                                        onChange={(e) => changeValue(e.target.value, 'pac_data_ini_sintoma')}
                                        readOnly
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md>
                                    <FloatingLabel label="Unidade Notificadora" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Unidade Notificadora"
                                            value={form.pac_unidade_notif}
                                            onChange={(e) => changeValue(e.target.value, 'pac_unidade_notif')}
                                            readOnly
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <div>Profissional da Saúde</div>
                                    <Form.Control type="text" value={form.pac_prof_saude} readOnly />
                                </Col>

                                {form.monitorado === 'Confirmado' ? (
                                    ''
                                ) : (
                                    <Col md>
                                        <FloatingLabel label="Paciente com COVID-19 confirmado" className="mb-3">
                                            <Form.Control
                                                type="text"
                                                placeholder="Paciente com COVID-19 confirmado"
                                                value={form.paciente_origem}
                                                readOnly
                                            />
                                        </FloatingLabel>
                                    </Col>
                                )}

                                <Col md>
                                    <div className="mb-0">Monitorar paciente?</div>
                                    <Form.Control type="text" value={form.pac_monitorado} readOnly />
                                </Col>
                            </Row>

                            {/*<Row>
                            <Col md>
                                <div className="mb-0">Unidade de Saúde</div>

                                <Form.Select>
                                    <option value="">Selecione</option>
                                    <option value="S">Sim</option>
                                    <option value="N">Não</option>
                                </Form.Select>
                            </Col>
                        </Row>*/}
                        </div>
                    </div>
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="card-body">DADOS DO PACIENTE</div>
                            <Row>
                                <Col md>
                                    <FloatingLabel label="ID" className="mb-3">
                                        <Form.Control type="text" placeholder="ID" value={form.id} readOnly />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel label="CPF" className="mb-3">
                                        <Form.Control type="text" placeholder="CPF" value={form.pac_cpf} readOnly />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel label="Nome" className="mb-3">
                                        <Form.Control type="text" placeholder="Nome" value={form.pac_nome} readOnly />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col md>
                                    <div className="mb-0">Sexo</div>
                                    <Form.Control type="text" value={form.pac_sexo} readOnly />
                                </Col>

                                <Col md>
                                    <FloatingLabel label="Data de Nascimento" className="mb-3">
                                        <Form.Control
                                            type="date"
                                            placeholder="Data de Nascimento"
                                            value={form.pac_dt_nasc}
                                            onChange={(e) => changeValue(e.target.value, 'pac_dt_nasc')}
                                            readOnly
                                        />
                                    </FloatingLabel>
                                </Col>

                                <Col md>
                                    <FloatingLabel label="Idade" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Idade"
                                            value={form.pac_idade}
                                            onChange={(e) => changeValue(e.target.value, 'pac_idade')}
                                            readOnly
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>

                            <Row>
                                <Col md>
                                    <div className="mb-0">Gestante</div>
                                    <Form.Control type="text" placeholder="" value={form.pac_gestante} readOnly />
                                </Col>
                                <Col md>
                                    <FloatingLabel label="Nome da Mãe" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Nome da Mãe"
                                            value={form.pac_mae}
                                            onChange={(e) => changeValue(e.target.value, 'pac_mae')}
                                            readOnly
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel label="CEP" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="CEP"
                                            value={form.pac_end_cep}
                                            onChange={(e) => changeValue(e.target.value, 'pac_end_cep')}
                                            readOnly
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>

                            <Row>
                                <Col md>
                                    <FloatingLabel label="Endereço" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Endereço"
                                            value={form.pac_end_rua}
                                            onChange={(e) => changeValue(e.target.value, 'pac_end_rua')}
                                            readOnly
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel label="Número" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Número"
                                            value={form.pac_end_numero}
                                            onChange={(e) => changeValue(e.target.value, 'pac_end_numero')}
                                            readOnly
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel label="Complemento" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Complemento"
                                            value={form.pac_end_complemento}
                                            onChange={(e) => changeValue(e.target.value, 'pac_end_complemento')}
                                            readOnly
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col md>
                                    <FloatingLabel label="Bairro" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Bairro"
                                            value={form.pac_end_bairro}
                                            onChange={(e) => changeValue(e.target.value, 'pac_end_bairro')}
                                            readOnly
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel label="Cidade" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Cidade"
                                            value={form.pac_cidade}
                                            onChange={(e) => changeValue(e.target.value, 'pac_cidade')}
                                            readOnly
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel label="UF" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="UF"
                                            value={form.pac_uf}
                                            onChange={(e) => changeValue(e.target.value, 'pac_uf')}
                                            readOnly
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col md>
                                    <FloatingLabel label="Telefone Principal" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Telefone Principal"
                                            value={form.pac_telefone_1}
                                            onChange={(e) => changeValue(e.target.value, 'pac_telefone_1')}
                                            readOnly
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel label="Telefone" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Telefone"
                                            value={form.pac_telefone_2}
                                            onChange={(e) => changeValue(e.target.value, 'pac_telefone_2')}
                                            readOnly
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel label="Ocupação" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Ocupação"
                                            value={form.pac_ocupacao}
                                            onChange={(e) => changeValue(e.target.value, 'pac_ocupacao')}
                                            readOnly
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="card-body">COMORBIDADES</div>
                            <Row className="mb-3">
                                <Col md>
                                    <div className="mb-0">Doenças Pulmonares</div>
                                    <Form.Control type="text" placeholder="" value={form.pac_notif_asma} readOnly />
                                </Col>
                                <Col md>
                                    <div className="mb-0">Doenças cardíacas crônicas e/ou hipertensão</div>
                                    <Form.Control type="text" placeholder="" value={form.pac_notif_cardiaco} readOnly />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md>
                                    <div className="mb-0">Diabetes</div>
                                    <Form.Control type="text" placeholder="" value={form.pac_notif_diabetes} readOnly />
                                </Col>
                                <Col md>
                                    <div className="mb-0">Doenças Renais</div>
                                    <Form.Control type="text" placeholder="" value={form.pac_notif_renal} readOnly />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md>
                                    <div className="mb-0">
                                        Imunossupressão (hemodiálise, quimio/radioterapia, transplante, ou tratamento
                                        prolongado (mais de 10 dias) de corticóide)
                                    </div>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        value={form.pac_notif_imunossup}
                                        readOnly
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="card-body">DADOS EPIDEMIOLÓGICOS - PRIMEIROS SINTOMAS</div>
                            <Row className="mb-3">
                                <Col md>
                                    <div className="mb-0">Teve contato com caso de COVID-19?</div>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        value={form.pac_notif_contato_covid}
                                        readOnly
                                    />
                                </Col>
                                <Col md>
                                    <div className="mb-0">Febre</div>
                                    <Form.Control type="text" placeholder="" value={form.pac_febre} readOnly />
                                </Col>
                                {form.pac_febre === 'S' ? (
                                    <Col md>
                                        <FloatingLabel label="Temperatura" className="mb-3">
                                            <Form.Control
                                                type="text"
                                                placeholder="Temperatura"
                                                value={form.pac_febre_temp}
                                                onChange={(e) => changeValue(e.target.value, 'pac_febre_temp')}
                                                readOnly
                                            />
                                        </FloatingLabel>
                                    </Col>
                                ) : (
                                    ''
                                )}
                                <Col md>
                                    <div className="mb-0">Tosse</div>
                                    <Form.Control type="text" placeholder="" value={form.pac_tosse} readOnly />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md>
                                    <div className="mb-0">Desconforto respiratório</div>
                                    <Form.Control type="text" placeholder="" value={form.pac_dor_respirar} readOnly />
                                </Col>
                                <Col md>
                                    <div className="mb-0">Falta de ar</div>
                                    <Form.Control type="text" placeholder="" value={form.pac_falta_ar} readOnly />
                                </Col>
                                <Col md>
                                    <div className="mb-0">Dor de garganta</div>
                                    <Form.Control type="text" placeholder="" value={form.pac_dor_garganta} readOnly />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md>
                                    <div className="mb-0">Diarréia</div>
                                    <Form.Control type="text" placeholder="" value={form.pac_diarreia} readOnly />
                                </Col>
                                <Col md>
                                    <div className="mb-0">Diminuição/perda do olfato e/ou paladar</div>
                                    <Form.Control type="text" placeholder="" value={form.pac_olfato_paladar} readOnly />
                                </Col>
                                <Col md>
                                    <div className="mb-0">Cefaléia</div>
                                    <Form.Control type="text" placeholder="" value={form.pac_notif_cefaleia} readOnly />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md>
                                    <div className="mb-0">Mialgia</div>
                                    <Form.Control type="text" placeholder="" value={form.pac_notif_mialgia} readOnly />
                                </Col>
                                <Col md>
                                    <div className="mb-0">Fadiga aos mínimos esforços</div>
                                    <Form.Control type="text" placeholder="" value={form.pac_notif_fadiga} readOnly />
                                </Col>
                                <Col md>
                                    <div className="mb-0">Náusea e/ou vômito</div>
                                    <Form.Control type="text" placeholder="" value={form.pac_enjoo_vomito} readOnly />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md>
                                    <div className="mb-0">Congestão nasal e/ou coriza</div>

                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        value={form.pac_cong_nasal_coriza}
                                        readOnly
                                    />
                                </Col>
                                <Col md>
                                    <div className="mb-0">Indisposição e/ou fraqueza</div>

                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        value={form.pac_indisposicao_fraqueza}
                                        readOnly
                                    />
                                </Col>
                                <Col md>
                                    <div className="mb-0">Inapetência</div>

                                    <Form.Control type="text" placeholder="" value={form.pac_inapetencia} readOnly />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md>
                                    <div className="mb-0">Data da coleta GAL</div>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        value={new Date(form.data_coleta_gal).toLocaleString()}
                                        readOnly
                                    />
                                </Col>
                                <Col md>
                                    <div className="mb-0">Sugestão de Encerramento</div>
                                    <Form.Control
                                        type="date"
                                        placeholder=""
                                        value={new Date(form.pac_sugestao_encerramento).toISOString().split('T')[0]}
                                        onChange={(e) => changeValue(e.target.value, 'pac_sugestao_encerramento')}
                                        readOnly
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="card-body">
                                <FloatingLabel label="Observação / Resposta ao paciente">
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Observação / Resposta ao paciente"
                                        style={{ height: '100px' }}
                                        value={form.pac_notif_observacao}
                                        onChange={(e) => changeValue(e.target.value, 'pac_notif_observacao')}
                                        readOnly
                                    />
                                </FloatingLabel>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="card-body">EXAMES</div>
                            <Row className="mb-3">
                                <Col md>
                                    <div className="mb-0">IGM Resultado</div>
                                    <Form.Control type="text" placeholder="" value={form.pac_igm_result} readOnly />
                                </Col>
                                <Col md>
                                    <div className="mb-0">IGG Resultado</div>
                                    <Form.Control type="text" placeholder="" value={form.pac_igg_result} readOnly />
                                </Col>
                                <Col md>
                                    <div className="mb-0">RT-PCR</div>
                                    <Form.Control type="text" placeholder="" value={form.pac_rt_pcr_result} readOnly />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Form>
            </Container>
        </Container>
    );
}
