import Logged from '../util/check';

const Protected = ({ children }) => {
    const check = Logged.checkLogged();

    if (check) {
        return children;
    }

    return <>{Logged.checkLogged()}</>;
};

export default Protected;
