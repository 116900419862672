import React from 'react';
import { Routes, Route } from 'react-router-dom';

//PAGES LAYOUTS
import IndexLayout from './pages/home';
import Login from './pages/login/Login';

import { ToastContainer } from 'react-toastify';

//MENUS PAGE
import Home from './routes/homeRoutes';
import CasosConfirmados from './routes/casosConfirmRoutes';
import SistemaGal from './routes/sistemaGalRoutes';
import TabelaDoSistema from './routes/tabelasSistemaRoutes';
import MonitoramentoDigitro from './routes/monitorDigitroRoutes';
import Relatorios from './routes/relatoriosRoutes';
import { API_URL } from './services/util/constants';

import socket from './services/socket/socketio';

export default function AppRoutes(props) {
    return (
        <>
            <Routes>
                <Route path={'/' + API_URL} element={<IndexLayout />}>
                    {/*  <Route element={<Home />} /> */}
                    <Route index element={<Home />} />
                    <Route path={API_URL + '/*'} element={<CasosConfirmados socket={socket} />} />
                    <Route path={API_URL + 'sistema-gal/*'} element={<SistemaGal socket={socket} />} />
                    <Route path={API_URL + 'tabelas-do-sistema/*'} element={<TabelaDoSistema socket={socket} />} />
                    <Route
                        path={API_URL + 'monitoramento_digitro/*'}
                        element={<MonitoramentoDigitro socket={socket} />}
                    />
                    <Route path={API_URL + 'relatorios/*'} element={<Relatorios socket={socket} />} />
                </Route>
                <Route path={API_URL + '/login'} element={<Login />} />
                <Route path="*" element={<h1>Página não encontrada</h1>} />
            </Routes>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
}
