import React, { useState } from 'react';

//BOOTSTRAP REACT
import { Container, Button, Modal } from 'react-bootstrap';
//CSS
//import '../../../../../assets/css/pages/menus/tabelas-do-sistema/index.css';

//SERVICES
import HttpService from '../../../../../../services/http/casos_confirmados/monitoramento_service';

//COMPONENTS
import CustomTable from '../../../../../../components/customTable';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';

export default function ContatosRealizados(props) {
    const id = props.id;
    const [data, setData] = useState('');
    const [showModal, setShowModal] = useState(false);

    const formatButton = props.howButton;

    //REQUISICAO DE DADOS
    async function getContatos() {
        await HttpService.consultaHistoricoMonitoramento({
            id: id,
            page: 1,
            limit: 10
        })
            .then(async (response) => {
                const data = response.data;

                for (let i = 0; i < data.data.length; i++) {
                    const dataDB = response.data.data[i].pac_evo_dt_monitora;

                    const dataFormat = new Date(dataDB);

                    const dataFormatBr = dataFormat.toLocaleDateString('pt-BR');
                    //const horaFormatBr = dataFormat.toLocaleTimeString('pt-BR');

                    data.data[i].pac_evo_dt_monitora = dataFormatBr + ' ' /* + horaFormatBr */;
                }

                setData(data);
            })
            .catch((e) => {});
    }

    function handleShow() {
        getContatos();
        setShowModal(true);
    }
    function handleClose() {
        setShowModal(false);
    }

    return (
        <>
            {formatButton === true ? (
                <>
                    <Button variant="primary" onClick={handleShow}>
                        <FontAwesomeIcon icon={faClockRotateLeft} /> Histórico Monitoramento
                    </Button>{' '}
                </>
            ) : (
                <>
                    <FontAwesomeIcon onClick={handleShow} icon={faClockRotateLeft} />
                </>
            )}

            <Modal show={showModal} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Histórico de Monitoramento</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Container fluid className="container_content_index">
                        <Container fluid className="content_index full">
                            <div
                                className="header_tabela_gal"
                                style={{
                                    display: 'flex',
                                    height: '50px',
                                    width: '100%'
                                }}
                            ></div>
                            {data !== '' ? (
                                <div className="content_tabela_gal">
                                    <div className="d_table_tabela_gal">
                                        <CustomTable
                                            id="table-to-xls"
                                            header={data.header.map((result) => {
                                                return (
                                                    <>
                                                        <th key={`header${result.id}`}>{result.value}</th>
                                                    </>
                                                );
                                            })}
                                            data={data.data.map((result) => {
                                                return (
                                                    <>
                                                        <tr key={`linha${result.id}`}>
                                                            <td></td>
                                                            {data.header.map((resulty) => {
                                                                if (resulty.value === 'Data') {
                                                                    return (
                                                                        <>
                                                                            <td key={`celula${result.id}`}>
                                                                                {result.pac_evo_dt_monitora}
                                                                            </td>
                                                                        </>
                                                                    );
                                                                }
                                                                return (
                                                                    <>
                                                                        <td key={`celula${result.id}`}>
                                                                            {result[resulty.name]}
                                                                        </td>
                                                                    </>
                                                                );
                                                            })}
                                                        </tr>
                                                    </>
                                                );
                                            })}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="content_tabela_gal">
                                    <div className="d_table_tabela_gal">
                                        <div>Nenhum histórico encontrado</div>
                                    </div>
                                </div>
                            )}
                        </Container>{' '}
                    </Container>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
