import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Protected from '../services/routes/protected';

//PAGES
import Index from '../pages/home/casos_confirmados';

import PrimeiroAtendimento from '../pages/home/casos_confirmados/primeiro_atendimento';
import ViewPrimeiroAtendimento from '../pages/home/casos_confirmados/primeiro_atendimento/form_primeiro_atendimento';
import CadastroPrimeiroAtendimento from '../pages/home/casos_confirmados/primeiro_atendimento/novo_cadastro';

import Monitoramento from '../pages/home/casos_confirmados/monitoramento';
import ProfileMonitoramento from '../pages/home/casos_confirmados/monitoramento/view_profile';
//import CadastroSimplificado from '../pages/index/casos_confirmados/primeiro_atendimento/cadastro_simplificado';
//import ViewCadastro from '../pages/index/casos_confirmados/cadastro_simplificado/view-cadastro';

export default function CasosConfirmRoutes(props) {
    return (
        <Routes>
            <Route
                index
                element={
                    <Protected>
                        <Index />
                    </Protected>
                }
            />
            <Route
                path={`/primeiro-atendimento`}
                element={
                    <Protected>
                        <PrimeiroAtendimento socket={props.socket} />
                    </Protected>
                }
            />
            <Route
                path={`/primeiro-atendimento/cadastro`}
                element={
                    <Protected>
                        <CadastroPrimeiroAtendimento />
                    </Protected>
                }
            />
            <Route
                path={`/primeiro-atendimento/view/:id`}
                element={
                    <Protected>
                        <ViewPrimeiroAtendimento socket={props.socket} />
                    </Protected>
                }
            />
            <Route
                path={`/monitoramento`}
                element={
                    <Protected>
                        <Monitoramento socket={props.socket} />
                    </Protected>
                }
            />
            <Route
                path={`/monitoramento/view/:id`}
                element={
                    <Protected>
                        <ProfileMonitoramento socket={props.socket} />
                    </Protected>
                }
            />

            <Route path="*" element={<h1>Página não encontrada</h1>} />
        </Routes>
    );
}
