import axios from 'axios';
import { API_BASE_URL } from '../../util/constants';

class AuthService {
    login(data) {
        return axios.post(`${API_BASE_URL}/auth/login`, {
            username: data.username,
            password: data.password
        });
    }
    logout(data) {
        return axios.post(`${API_BASE_URL}/auth/logout`, null, {
            headers: { 'access-token': localStorage.getItem('access-token') }
        });
    }
    check_token_valid(data) {
        return axios.post(`${API_BASE_URL}/auth/checkToken`, null, {
            headers: { 'access-token': localStorage.getItem('access-token') }
        });
    }
}
export default new AuthService();
