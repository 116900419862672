import axios from 'axios';
import { API_BASE_URL } from '../../util/constants';

class RelatoriosService {
    consultaContatoRealizado(data, token) {
        return axios.post(`${API_BASE_URL}/relatorio/selectRelatorioContatoRealizado`, data, {
            headers: {
                'access-token': localStorage.getItem('access-token')
            }
        });
    }
    consultaPacientes(data, token) {
        return axios.post(`${API_BASE_URL}/relatorio/selectRelatorioPaciente`, data, {
            headers: {
                'access-token': localStorage.getItem('access-token')
            }
        });
    }
    searchPacientes(data, token) {
        return axios.post(`${API_BASE_URL}/relatorio/selectRelatorioPacienteByName`, data, {
            headers: {
                'access-token': localStorage.getItem('access-token')
            }
        });
    }
}
export default new RelatoriosService();
