import io from 'socket.io-client';

const deploy = process.env.REACT_APP_NODE_ENV;

const socket = {
    io: function () {
        let ioSocket = null;

        if (deploy === 'production') {
            ioSocket = io(`${window.location.origin.toString()}`, {
                path: '/api_monitor_covid/socket.io'
            });
        } else {
            ioSocket = io(`ws://localhost:3333`);
        }
        return ioSocket;
    }
};

export default new socket.io();
