import React, { useState } from 'react';

//BOOTSTRAP REACT
import { Button, Modal } from 'react-bootstrap';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

// import { API_BASE_URL } from '../services/util/constants';
// import Toast from '../services/util/toast';

const DeleteRegistro = (props) => {
    const { deleteFunction } = props;
    const [showModal, setShowModal] = useState(false);

    function handleShow(event) {
        setShowModal(true);
    }
    function handleClose(event) {
        setShowModal(false);
    }

    return (
        <>
            <Button variant="danger" onClick={handleShow}>
                <FontAwesomeIcon icon={faTrash} /> EXCLUIR
            </Button>

            <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Excluir registro</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <h5>Você tem certeza que deseja excluir o registro?</h5>
                    <br />
                    <i>Atenção: Esta ação não pode ser desfeita!</i>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={deleteFunction}>
                        Excluir
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DeleteRegistro;
