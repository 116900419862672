import React, { useState } from 'react';

//BOOTSTRAP REACT
import { Button, Modal, Row, Col, Form, FloatingLabel } from 'react-bootstrap';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTv } from '@fortawesome/free-solid-svg-icons';

//SERVICES
import HttpService from '../../../../../../services/http/casos_confirmados/monitoramento_service';
import Toast from '../../../../../../services/util/toast';

export default function ContatosRealizados(props) {
    const id = props.id;
    const [form, setForm] = useState('');
    const [showModal, setShowModal] = useState(false);
    const formatButton = props.howButton;

    const [errors, setErrors] = useState({});
    const findFormErrors = () => {
        const {
            data_monitoramento,
            pac_febre,
            pac_tosse,
            pac_dor_respirar,
            pac_dor_garganta,
            pac_diarreia,
            pac_olfato_paladar,
            pac_notif_cefaleia,
            pac_notif_mialgia,
            pac_enjoo_vomito,
            pac_desmaio,
            pac_notif_evo_caso,
            pac_notif_evo_outros
        } = form;
        const newErrors = {};

        if (!data_monitoramento || data_monitoramento === '') newErrors.data_monitoramento = 'Não pode ser vazio';
        if (!pac_febre || pac_febre === '') newErrors.pac_febre = 'Não pode ser vazio';
        if (!pac_tosse || pac_tosse === '') newErrors.pac_tosse = 'Não pode ser vazio';
        if (!pac_dor_respirar || pac_dor_respirar === '') newErrors.pac_dor_respirar = 'Não pode ser vazio';
        if (!pac_dor_garganta || pac_dor_garganta === '') newErrors.pac_dor_garganta = 'Não pode ser vazio';
        if (!pac_olfato_paladar || pac_olfato_paladar === '') newErrors.pac_olfato_paladar = 'Não pode ser vazio';
        if (!pac_notif_mialgia || pac_notif_mialgia === '') newErrors.pac_notif_mialgia = 'Não pode ser vazio';
        if (!pac_notif_cefaleia || pac_notif_cefaleia === '') newErrors.pac_notif_cefaleia = 'Não pode ser vazio';
        if (!pac_diarreia || pac_diarreia === '') newErrors.pac_diarreia = 'Não pode ser vazio';
        if (!pac_enjoo_vomito || pac_enjoo_vomito === '') newErrors.pac_enjoo_vomito = 'Não pode ser vazio';
        if (!pac_desmaio || pac_desmaio === '') newErrors.pac_desmaio = 'Não pode ser vazio';
        if (!pac_notif_evo_caso || pac_notif_evo_caso === '') newErrors.pac_notif_evo_caso = 'Não pode ser vazio';
        if (!pac_notif_evo_outros || pac_notif_evo_outros === '') newErrors.pac_notif_evo_outros = 'Não pode ser vazio';
        return newErrors;
    };

    function handleShow() {
        setShowModal(true);
    }
    function handleClose() {
        setShowModal(false);
    }
    function limparCampos() {
        setForm({
            data_monitoramento: '',
            pac_febre: '',
            pac_tosse: '',
            pac_dor_respirar: '',
            pac_dor_garganta: '',
            pac_diarreia: '',
            pac_olfato_paladar: '',
            pac_notif_cefaleia: '',
            pac_notif_mialgia: '',
            pac_enjoo_vomito: '',
            pac_desmaio: '',
            pac_notif_evo_caso: '',
            pac_notif_evo_outros: ''
        });
        setErrors({});
    }

    //MUDAR VALORES DO FORMULÁRIO
    const changeValue = (event, key) => {
        const value = event;
        const name = key;
        const obj = JSON.parse(`{"${name}": "${value}"}`);
        setForm({ ...form, ...obj });
    };

    async function updatePaciente() {
        const newErrors = findFormErrors();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            await HttpService.insertMonitoramento({ ...form, pac_id: id })
                .then(async (response) => {
                    Toast.sucess('Sucesso');
                })
                .catch((e) => {
                    Toast.error(e.response.data.message);
                });
        }
    }

    return (
        <>
            {formatButton === true ? (
                <>
                    <Button variant="primary" onClick={handleShow}>
                        <FontAwesomeIcon icon={faTv} /> Monitorar
                    </Button>{' '}
                </>
            ) : (
                <>
                    <FontAwesomeIcon onClick={handleShow} icon={faTv} />
                </>
            )}

            <Modal show={showModal} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Monitoramento</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <div className="card mb-3">
                            <div className="card-body">
                                <Row className="mb-3">
                                    <Col md>
                                        <div className="mb-0">Data</div>
                                        <Form.Control
                                            type="date"
                                            placeholder=""
                                            value={form.data_monitoramento}
                                            onChange={(e) => changeValue(e.target.value, 'data_monitoramento')}
                                            /*   isInvalid={!!errors.data_monitoramento} */
                                        />
                                    </Col>
                                    <Col md>
                                        <div className="mb-0">Febre</div>

                                        <Form.Select
                                            value={form.pac_febre}
                                            onChange={(e) => changeValue(e.target.value, 'pac_febre')}
                                            isInvalid={!!errors.pac_febre}
                                        >
                                            <option value="">Selecione</option>
                                            <option value="S">Sim</option>
                                            <option value="N">Não</option>
                                        </Form.Select>
                                    </Col>
                                    {form.pac_febre === 'S' ? (
                                        <Col md>
                                            <FloatingLabel label="Temperatura" className="mb-3">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Temperatura"
                                                    value={form.pac_febre_temp}
                                                    onChange={(e) => changeValue(e.target.value, 'pac_febre_temp')}
                                                />
                                            </FloatingLabel>
                                        </Col>
                                    ) : (
                                        ''
                                    )}
                                    <Col md>
                                        <div className="mb-0">Tosse</div>

                                        <Form.Select
                                            value={form.pac_tosse}
                                            onChange={(e) => changeValue(e.target.value, 'pac_tosse')}
                                            isInvalid={!!errors.pac_tosse}
                                        >
                                            <option value="">Selecione</option>
                                            <option value="S">Sim</option>
                                            <option value="N">Não</option>
                                        </Form.Select>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md>
                                        <div className="mb-0">Desconforto respiratório / Dispnéia</div>

                                        <Form.Select
                                            value={form.pac_dor_respirar}
                                            onChange={(e) => changeValue(e.target.value, 'pac_dor_respirar')}
                                            isInvalid={!!errors.pac_dor_respirar}
                                        >
                                            <option value="">Selecione</option>
                                            <option value="S">Sim</option>
                                            <option value="N">Não</option>
                                        </Form.Select>
                                    </Col>
                                    <Col md>
                                        <div className="mb-0">Dor de garganta</div>

                                        <Form.Select
                                            value={form.pac_dor_garganta}
                                            onChange={(e) => changeValue(e.target.value, 'pac_dor_garganta')}
                                            isInvalid={!!errors.pac_dor_garganta}
                                        >
                                            <option value="">Selecione</option>
                                            <option value="S">Sim</option>
                                            <option value="N">Não</option>
                                        </Form.Select>
                                    </Col>
                                    <Col md>
                                        <div className="mb-0">Diminuição / perda do olfato e/ou paladar</div>

                                        <Form.Select
                                            value={form.pac_olfato_paladar}
                                            onChange={(e) => changeValue(e.target.value, 'pac_olfato_paladar')}
                                            isInvalid={!!errors.pac_olfato_paladar}
                                        >
                                            <option value="">Selecione</option>
                                            <option value="S">Sim</option>
                                            <option value="N">Não</option>
                                        </Form.Select>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md>
                                        <div className="mb-0">Mialgia / Fraqueza muscular</div>

                                        <Form.Select
                                            value={form.pac_notif_mialgia}
                                            onChange={(e) => changeValue(e.target.value, 'pac_notif_mialgia')}
                                            isInvalid={!!errors.pac_notif_mialgia}
                                        >
                                            <option value="">Selecione</option>
                                            <option value="S">Sim</option>
                                            <option value="N">Não</option>
                                        </Form.Select>
                                    </Col>
                                    <Col md>
                                        <div className="mb-0">Cefaléia</div>

                                        <Form.Select
                                            value={form.pac_notif_cefaleia}
                                            onChange={(e) => changeValue(e.target.value, 'pac_notif_cefaleia')}
                                            isInvalid={!!errors.pac_notif_cefaleia}
                                        >
                                            <option value="">Selecione</option>
                                            <option value="S">Sim</option>
                                            <option value="N">Não</option>
                                        </Form.Select>
                                    </Col>
                                    <Col md>
                                        <div className="mb-0">Diarréia</div>

                                        <Form.Select
                                            value={form.pac_diarreia}
                                            onChange={(e) => changeValue(e.target.value, 'pac_diarreia')}
                                            isInvalid={!!errors.pac_diarreia}
                                        >
                                            <option value="">Selecione</option>
                                            <option value="S">Sim</option>
                                            <option value="N">Não</option>
                                        </Form.Select>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md>
                                        <div className="mb-0">Náusea e/ou vômito</div>

                                        <Form.Select
                                            value={form.pac_enjoo_vomito}
                                            onChange={(e) => changeValue(e.target.value, 'pac_enjoo_vomito')}
                                            isInvalid={!!errors.pac_enjoo_vomito}
                                        >
                                            <option value="">Selecione</option>
                                            <option value="S">Sim</option>
                                            <option value="N">Não</option>
                                        </Form.Select>
                                    </Col>
                                    <Col md>
                                        <div className="mb-0">Sensação de desmaio</div>

                                        <Form.Select
                                            value={form.pac_desmaio}
                                            onChange={(e) => changeValue(e.target.value, 'pac_desmaio')}
                                            isInvalid={!!errors.pac_desmaio}
                                        >
                                            <option value="">Selecione</option>
                                            <option value="S">Sim</option>
                                            <option value="N">Não</option>
                                        </Form.Select>
                                    </Col>
                                    <Col md>
                                        <div className="mb-0">Evolução do caso</div>

                                        <Form.Select
                                            value={form.pac_notif_evo_caso}
                                            onChange={(e) => changeValue(e.target.value, 'pac_notif_evo_caso')}
                                            isInvalid={!!errors.pac_notif_evo_caso}
                                        >
                                            <option value="">Selecione</option>
                                            <option value="1">Permanece em isolamento domiciliar</option>
                                            <option value="2">Encaminhado à avaliação médica</option>
                                            <option value="3">Internamento</option>
                                            <option value="4">Alta</option>
                                            <option value="5">Paciente solitou a saída do monitoramento</option>
                                        </Form.Select>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md>
                                        <FloatingLabel label="Observação">
                                            <Form.Control
                                                as="textarea"
                                                placeholder="Observação"
                                                style={{ height: '100px' }}
                                                value={form.pac_notif_evo_outros}
                                                onChange={(e) => changeValue(e.target.value, 'pac_notif_evo_outros')}
                                                isInvalid={!!errors.pac_notif_evo_outros}
                                            />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button variant="secondary" onClick={limparCampos}>
                        Limpar
                    </Button>
                    <Button variant="primary" onClick={updatePaciente}>
                        Salvar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
