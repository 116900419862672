import React, { useState } from 'react';

//BOOTSTRAP REACT
import { Button, Modal, Form, Row, Col, FloatingLabel } from 'react-bootstrap';

//SERVICES
import HttpService from '../../../../../services/http/casos_confirmados/primeiro_atendimento_service';
import Toast from '../../../../../services/util/toast';
import Util from '../../../../../services/util/util';

export default function CadastroSimplificado(props) {
    const [data, setData] = useState({
        nome: '',
        dt_nasc: '',
        idade: '',
        telefone: '',
        primeiro_sintomas: '',
        liberacao_exame: '',
        unidade_notif: '',
        igg_result: '',
        igm_result: '',
        rt_pcr_result: ''
    });
    const [showModal, setShowModal] = useState(false);

    function handleShow(event) {
        setShowModal(true);
    }

    function handleClean(event) {
        setData({
            nome: '',
            dt_nasc: '',
            idade: '',
            telefone: '',
            primeiro_sintomas: '',
            liberacao_exame: '',
            unidade_notif: '',
            igg_result: '',
            igm_result: '',
            rt_pcr_result: ''
        });
    }

    function handleClose(event) {
        setShowModal(false);
    }

    function handleSubmit(event) {
        insertData();
    }

    async function insertData() {
        await HttpService.insertCadastroSimplificado(data)
            .then((response) => {
                handleClose();
                props.getDataTable(true);
                Toast.sucess('Cadastrado com sucesso');
            })
            .catch((e) => {
                Toast.error(e.response.data.message.message);
            });
    }

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Cadastro Simplificado
            </Button>

            <Modal show={showModal} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Cadastro Simplificado</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row className="g-2">
                            <Col md>
                                <FloatingLabel controlId="floatingNome" label="Nome" className="mb-3">
                                    <Form.Control
                                        type="text"
                                        placeholder="Nome"
                                        value={data.nome}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                nome: e.target.value
                                            });
                                        }}
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingDatNasc" label="Data De Nascimento" className="mb-3">
                                    <Form.Control
                                        type="date"
                                        placeholder="Data De Nascimento"
                                        value={data.dt_nasc}
                                        onChange={(e) => {
                                            const dataNascimento = e.target.value;
                                            const idade = Util.transformaEmIdade(dataNascimento);
                                            setData({
                                                ...data,
                                                dt_nasc: dataNascimento,
                                                idade: idade
                                            });
                                        }}
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row className="g-2">
                            <Col md>
                                <FloatingLabel controlId="floatingTelefone" label="Telefone" className="mb-3">
                                    <Form.Control
                                        type="text"
                                        placeholder="Telefone"
                                        value={data.telefone}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                telefone: e.target.value
                                            });
                                        }}
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row className="g-2">
                            <Col md>
                                <FloatingLabel
                                    controlId="floatingPrimeirosSintomas"
                                    label="Primeiros Sintomas / Data da Coleta / Último contato"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="date"
                                        placeholder="Primeiros Sintomas / Data da Coleta / Último contato"
                                        value={data.primeiro_sintomas}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                primeiro_sintomas: e.target.value
                                            });
                                        }}
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row className="g-2">
                            <Col md>
                                <FloatingLabel
                                    controlId="floatingUnidadeNotificadora"
                                    label="Unidade Notificadora"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Unidade Notificadora"
                                        value={data.unidade_notif}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                unidade_notif: e.target.value
                                            });
                                        }}
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel
                                    controlId="floatingPrimeirosSintomas"
                                    label="Data de liberação do exame"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="date"
                                        placeholder="Data de liberação do exame"
                                        value={data.liberacao_exame}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                liberacao_exame: e.target.value
                                            });
                                        }}
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row className="g-2">
                            <Col md>
                                <FloatingLabel controlId="floatingIgg" label="IGG Resultado">
                                    <Form.Select
                                        aria-label="Selecione"
                                        value={data.igg_result}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                igg_result: e.target.value
                                            });
                                        }}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="S">Positivo</option>
                                        <option value="N">Negativo</option>
                                        <option value="R">Não Realizado</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingIgm" label="IGM Resultado">
                                    <Form.Select
                                        aria-label="Selecione"
                                        value={data.igm_result}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                igm_result: e.target.value
                                            });
                                        }}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="S">Positivo</option>
                                        <option value="N">Negativo</option>
                                        <option value="R">Não Realizado</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingRt" label="RT-PCR">
                                    <Form.Select
                                        aria-label="Selecione"
                                        value={data.rt_pcr_result}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                rt_pcr_result: e.target.value
                                            });
                                        }}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="D">Detectável</option>
                                        <option value="N">Não Detectável</option>
                                        <option value="R">Não Realizado</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="secondary" onClick={handleClean}>
                        Limpar
                    </Button>
                    <Button variant="primary" type="submit" onClick={handleSubmit}>
                        Salvar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
