import jwt from 'jwt-decode';
import { API_URL } from './constants';

class User {
    constructor() {
        this.userName = '';
        this.user = false;
        this.token = '';
    }

    checkTokenIsValid() {
        let token = localStorage.getItem('access-token');
        if (token === '' || token == null) {
            return false;
        }
        const tokenDecode = jwt(token);
        let timestamp = new Date().getTime() / 1000;
        if (timestamp <= tokenDecode.exp) {
            this.userName = tokenDecode.id;
            return true;
        } else {
            return false;
        }
    }

    updateName() {
        let token = localStorage.getItem('access-token');
        if (token === '' || token == null) {
            return false;
        }
        const tokenDecode = jwt(token);
        this.userName = tokenDecode.id;
        return this.userName;
    }

    checkLogged() {
        const check = this.checkTokenIsValid();
        if (check === false) {
            this.user = false;
            this.token = '';
            localStorage.removeItem('access-token');
            window.location.href = API_URL + '/login';
            return false;
        }
        this.user = true;
        this.token = localStorage.getItem('access-token');
        return true;
    }

    checkToken() {
        const check = this.checkLogged();
        if (check === false) {
            return false;
        }
        return true;
    }
}

export default new User();
