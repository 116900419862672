import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

//BOOTSTRAP REACT
import { Container, Button, Form, Row, Col, FloatingLabel } from 'react-bootstrap';
import '../style.css';

//COMPONENTS
import Loading from '../../../../../components/loading';
import Error from '../../../../errors/error';

//SERVICES
import HttpService from '../../../../../services/http/casos_confirmados/primeiro_atendimento_service';
import Toast from '../../../../../services/util/toast';

export default function ViewPrimeiroAtendimento(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const findFormErrors = () => {
        const {
            pac_confirmado,
            pac_data_liberacao_exame,
            pac_data_ini_sintoma,
            pac_unidade_notif,
            pac_prof_saude,

            pac_nome,
            pac_sexo,
            pac_dt_nasc,
            pac_idade,
            pac_mae,
            pac_end_cep,
            pac_end_rua,
            pac_end_numero,
            pac_end_bairro,
            pac_cidade,
            pac_uf,
            pac_telefone_1,

            pac_notif_asma,
            pac_notif_cardiaco,
            pac_notif_diabetes,
            pac_notif_renal,
            pac_notif_imunossup,
            pac_notif_contato_covid,
            pac_febre,
            pac_tosse,
            pac_dor_respirar,
            pac_falta_ar,
            pac_dor_garganta,
            pac_diarreia,
            pac_olfato_paladar,
            pac_notif_cefaleia,
            pac_notif_mialgia,
            pac_notif_fadiga,
            pac_enjoo_vomito,
            /*   pac_desmaio, */
            pac_cong_nasal_coriza,
            pac_indisposicao_fraqueza,
            pac_inapetencia,

            pac_igm_result,
            pac_igg_result,
            pac_rt_pcr_result
        } = form;
        const newErrors = {};

        if (!pac_confirmado || pac_confirmado === '') newErrors.pac_confirmado = 'Não pode ser vazio';
        if (!pac_data_liberacao_exame || pac_data_liberacao_exame === '')
            newErrors.pac_data_liberacao_exame = 'Não pode ser vazio';
        if (!pac_data_ini_sintoma || pac_data_ini_sintoma === '') newErrors.pac_data_ini_sintoma = 'Não pode ser vazio';
        if (!pac_unidade_notif || pac_unidade_notif === '') newErrors.pac_unidade_notif = 'Não pode ser vazio';
        if (!pac_prof_saude || pac_prof_saude === '') newErrors.pac_prof_saude = 'Não pode ser vazio';
        /*  if (!pac_monitorado || pac_monitorado === '')
            newErrors.pac_monitorado = 'Não pode ser vazio'; */
        if (!pac_nome || pac_nome === '') newErrors.pac_nome = 'Não pode ser vazio';
        if (!pac_sexo || pac_sexo === '') newErrors.pac_sexo = 'Não pode ser vazio';
        if (!pac_dt_nasc || pac_dt_nasc === '') newErrors.pac_dt_nasc = 'Não pode ser vazio';
        if (!pac_idade || pac_idade === '') newErrors.pac_idade = 'Não pode ser vazio';
        if (!pac_mae || pac_mae === '') newErrors.pac_mae = 'Não pode ser vazio';
        if (!pac_end_cep || pac_end_cep === '') newErrors.pac_end_cep = 'Não pode ser vazio';
        if (!pac_end_rua || pac_end_rua === '') newErrors.pac_end_rua = 'Não pode ser vazio';
        if (!pac_end_numero || pac_end_numero === '') newErrors.pac_end_numero = 'Não pode ser vazio';
        if (!pac_end_bairro || pac_end_bairro === '') newErrors.pac_end_bairro = 'Não pode ser vazio';
        if (!pac_cidade || pac_cidade === '') newErrors.pac_cidade = 'Não pode ser vazio';
        if (!pac_uf || pac_uf === '') newErrors.pac_uf = 'Não pode ser vazio';
        if (!pac_telefone_1 || pac_telefone_1 === '') newErrors.pac_telefone_1 = 'Não pode ser vazio';
        /*  if (!pac_ocupacao || pac_ocupacao === '')
            newErrors.pac_ocupacao = 'Não pode ser vazio'; */
        if (!pac_notif_asma || pac_notif_asma === '') newErrors.pac_notif_asma = 'Não pode ser vazio';
        if (!pac_notif_cardiaco || pac_notif_cardiaco === '') newErrors.pac_notif_cardiaco = 'Não pode ser vazio';
        if (!pac_notif_diabetes || pac_notif_diabetes === '') newErrors.pac_notif_diabetes = 'Não pode ser vazio';
        if (!pac_notif_renal || pac_notif_renal === '') newErrors.pac_notif_renal = 'Não pode ser vazio';
        if (!pac_notif_imunossup || pac_notif_imunossup === '') newErrors.pac_notif_imunossup = 'Não pode ser vazio';
        if (!pac_notif_contato_covid || pac_notif_contato_covid === '')
            newErrors.pac_notif_contato_covid = 'Não pode ser vazio';
        if (!pac_febre || pac_febre === '') newErrors.pac_febre = 'Não pode ser vazio';
        if (!pac_tosse || pac_tosse === '') newErrors.pac_tosse = 'Não pode ser vazio';
        if (!pac_dor_respirar || pac_dor_respirar === '') newErrors.pac_dor_respirar = 'Não pode ser vazio';
        if (!pac_falta_ar || pac_falta_ar === '') newErrors.pac_falta_ar = 'Não pode ser vazio';
        if (!pac_dor_garganta || pac_dor_garganta === '') newErrors.pac_dor_garganta = 'Não pode ser vazio';
        if (!pac_diarreia || pac_diarreia === '') newErrors.pac_diarreia = 'Não pode ser vazio';
        if (!pac_olfato_paladar || pac_olfato_paladar === '') newErrors.pac_olfato_paladar = 'Não pode ser vazio';
        if (!pac_notif_cefaleia || pac_notif_cefaleia === '') newErrors.pac_notif_cefaleia = 'Não pode ser vazio';
        if (!pac_notif_mialgia || pac_notif_mialgia === '') newErrors.pac_notif_mialgia = 'Não pode ser vazio';
        if (!pac_notif_fadiga || pac_notif_fadiga === '') newErrors.pac_notif_fadiga = 'Não pode ser vazio';
        if (!pac_enjoo_vomito || pac_enjoo_vomito === '') newErrors.pac_enjoo_vomito = 'Não pode ser vazio';
        /* if (!pac_desmaio || pac_desmaio === '') newErrors.pac_desmaio = 'Não pode ser vazio'; */
        if (!pac_cong_nasal_coriza || pac_cong_nasal_coriza === '')
            newErrors.pac_cong_nasal_coriza = 'Não pode ser vazio';
        if (!pac_indisposicao_fraqueza || pac_indisposicao_fraqueza === '')
            newErrors.pac_indisposicao_fraqueza = 'Não pode ser vazio';
        if (!pac_inapetencia || pac_inapetencia === '') newErrors.pac_inapetencia = 'Não pode ser vazio';
        /*  if (!pac_sugestao_encerramento || pac_sugestao_encerramento === '')
            newErrors.pac_sugestao_encerramento = 'Não pode ser vazio'; */
        if (!pac_igm_result || pac_igm_result === '') newErrors.pac_igm_result = 'Não pode ser vazio';
        if (!pac_igg_result || pac_igg_result === '') newErrors.pac_igg_result = 'Não pode ser vazio';
        if (!pac_rt_pcr_result || pac_rt_pcr_result === '') newErrors.pac_rt_pcr_result = 'Não pode ser vazio';
        return newErrors;
    };

    useEffect(() => {
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function updatePaciente() {
        const newErrors = findFormErrors();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            Toast.error('Preencha todos os campos');
        } else {
            setIsLoading(true);
            setIsError(false);
            await HttpService.saveCadastro(form)
                .then(async (response) => {
                    setIsLoading(false);
                    Toast.sucess('Cadastrado com sucesso!');
                    window.location = '/casos-confirmados/monitoramento/';
                })
                .catch((e) => {
                    setIsLoading(false);
                    /* alert('Preencha todos os campos!'); */
                    Toast.error(e.response.data.message);
                });
        }
    }

    //MUDAR VALORES DO FORMULÁRIO
    const changeValue = (event, key) => {
        const value = event;
        const name = key;
        const obj = JSON.parse(`{"${name}": "${value.toUpperCase()}"}`);
        setForm({ ...form, ...obj });
    };

    if (isError) {
        return <Error />;
    }
    if (isLoading) {
        return <Loading />;
    }

    return (
        <Container fluid className="container_content_index">
            <Container fluid className="content_index primeiroAtendimentoFlex">
                <div
                    style={{
                        marginTop: 15
                    }}
                ></div>
                <Form>
                    <div className="card mb-3">
                        <div className="card-body">
                            <Row>
                                <Col md>
                                    <div>Confirmado COVID-19</div>

                                    <Form.Select
                                        value={form.pac_confirmado}
                                        onChange={(e) => changeValue(e.target.value, 'pac_confirmado')}
                                        isInvalid={!!errors.pac_confirmado}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </Form.Select>
                                </Col>

                                <Col md>
                                    <FloatingLabel label="Data da liberação do exame" className="mb-3">
                                        <Form.Control
                                            type="date"
                                            placeholder="Data da liberação do exame"
                                            value={form.pac_data_liberacao_exame}
                                            onChange={(e) => changeValue(e.target.value, 'pac_data_liberacao_exame')}
                                            isInvalid={!!errors.pac_data_liberacao_exame}
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <div className="mb-0">Primeiros sintomas /Coleta/Último contato</div>
                                    <Form.Control
                                        type="date"
                                        placeholder=""
                                        value={form.pac_data_ini_sintoma}
                                        onChange={(e) => changeValue(e.target.value, 'pac_data_ini_sintoma')}
                                        isInvalid={!!errors.pac_data_ini_sintoma}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md>
                                    <FloatingLabel label="Unidade Notificadora" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Unidade Notificadora"
                                            value={form.pac_unidade_notif}
                                            onChange={(e) => changeValue(e.target.value, 'pac_unidade_notif')}
                                            isInvalid={!!errors.pac_unidade_notif}
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <div>Profissional da Saúde</div>

                                    <Form.Select
                                        value={form.pac_prof_saude}
                                        onChange={(e) => changeValue(e.target.value, 'pac_prof_saude')}
                                        isInvalid={!!errors.pac_prof_saude}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </Form.Select>
                                </Col>

                                {/*  {form.monitorado === 'Confirmado' ? (
                                    ''
                                ) : (
                                    <Col md>
                                        <FloatingLabel
                                            label="Paciente com COVID-19 confirmado"
                                            className="mb-3"
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="Paciente com COVID-19 confirmado"
                                                value={form.paciente_origem}
                                                isInvalid={
                                                    !!errors.paciente_origem
                                                }
                                            />
                                        </FloatingLabel>
                                    </Col>
                                )} */}

                                {/*  <Col md>
                                    <div className="mb-0">
                                        Monitorar paciente?
                                    </div>

                                    <Form.Select
                                        value={form.pac_monitorado}
                                        onChange={(e) =>
                                            changeValue(
                                                e.target.value,
                                                'pac_monitorado'
                                            )
                                        }
                                        isInvalid={!!errors.pac_monitorado}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </Form.Select>
                                </Col> */}
                            </Row>

                            {/*<Row>
                            <Col md>
                                <div className="mb-0">Unidade de Saúde</div>

                                <Form.Select>
                                    <option value="">Selecione</option>
                                    <option value="S">Sim</option>
                                    <option value="N">Não</option>
                                </Form.Select>
                            </Col>
                        </Row>*/}
                        </div>
                    </div>
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="card-body">DADOS DO PACIENTE</div>
                            <Row>
                                {/* <Col md>
                                    <FloatingLabel label="ID" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="ID"
                                            value={form.id}
                                            readOnly
                                        />
                                    </FloatingLabel>
                                </Col> */}

                                <Col md>
                                    <FloatingLabel label="Nome" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Nome"
                                            value={form.pac_nome}
                                            onChange={(e) => changeValue(e.target.value, 'pac_nome')}
                                            isInvalid={!!errors.pac_nome}
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel label="CPF" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="CPF"
                                            value={form.pac_cpf}
                                            onChange={(e) => changeValue(e.target.value, 'pac_cpf')}
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel label="Data de Nascimento" className="mb-3">
                                        <Form.Control
                                            type="date"
                                            placeholder="Data de Nascimento"
                                            value={form.pac_dt_nasc}
                                            onChange={(e) => changeValue(e.target.value, 'pac_dt_nasc')}
                                            isInvalid={!!errors.pac_dt_nasc}
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col md>
                                    <div className="mb-0">Sexo</div>

                                    <Form.Select
                                        value={form.pac_sexo}
                                        onChange={(e) => changeValue(e.target.value, 'pac_sexo')}
                                        isInvalid={!!errors.pac_sexo}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="M">Masculino</option>
                                        <option value="F">Feminino</option>
                                    </Form.Select>
                                </Col>

                                <Col md>
                                    <FloatingLabel label="Nome da Mãe" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Nome da Mãe"
                                            value={form.pac_mae}
                                            onChange={(e) => changeValue(e.target.value, 'pac_mae')}
                                            isInvalid={!!errors.pac_mae}
                                        />
                                    </FloatingLabel>
                                </Col>

                                <Col md>
                                    <FloatingLabel label="Idade" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Idade"
                                            value={form.pac_idade}
                                            onChange={(e) => changeValue(e.target.value, 'pac_idade')}
                                            isInvalid={!!errors.pac_idade}
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>

                            <Row>
                                <Col md>
                                    <div className="mb-0">Gestante</div>

                                    <Form.Select
                                        value={form.pac_gestante}
                                        onChange={(e) => changeValue(e.target.value, 'pac_gestante')}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                        <option value="">Ignorado</option>
                                    </Form.Select>
                                </Col>

                                <Col md>
                                    <FloatingLabel label="CEP" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="CEP"
                                            value={form.pac_end_cep}
                                            onChange={(e) => changeValue(e.target.value, 'pac_end_cep')}
                                            isInvalid={!!errors.pac_end_cep}
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>

                            <Row>
                                <Col md>
                                    <FloatingLabel label="Endereço" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Endereço"
                                            value={form.pac_end_rua}
                                            onChange={(e) => changeValue(e.target.value, 'pac_end_rua')}
                                            isInvalid={!!errors.pac_end_rua}
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel label="Número" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Número"
                                            value={form.pac_end_numero}
                                            onChange={(e) => changeValue(e.target.value, 'pac_end_numero')}
                                            isInvalid={!!errors.pac_end_numero}
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel label="Complemento" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Complemento"
                                            value={form.pac_end_complemento}
                                            onChange={(e) => changeValue(e.target.value, 'pac_end_complemento')}
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col md>
                                    <FloatingLabel label="Bairro" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Bairro"
                                            value={form.pac_end_bairro}
                                            onChange={(e) => changeValue(e.target.value, 'pac_end_bairro')}
                                            isInvalid={!!errors.pac_end_bairro}
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel label="Cidade" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Cidade"
                                            value={form.pac_cidade}
                                            onChange={(e) => changeValue(e.target.value, 'pac_cidade')}
                                            isInvalid={!!errors.pac_cidade}
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel label="UF" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="UF"
                                            value={form.pac_uf}
                                            onChange={(e) => changeValue(e.target.value, 'pac_uf')}
                                            isInvalid={!!errors.pac_uf}
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col md>
                                    <FloatingLabel label="Telefone Principal" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Telefone Principal"
                                            value={form.pac_telefone_1}
                                            onChange={(e) => changeValue(e.target.value, 'pac_telefone_1')}
                                            isInvalid={!!errors.pac_telefone_1}
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel label="Telefone" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Telefone"
                                            value={form.pac_telefone_2}
                                            onChange={(e) => changeValue(e.target.value, 'pac_telefone_2')}
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel label="Ocupação" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Ocupação"
                                            value={form.pac_ocupacao}
                                            onChange={(e) => changeValue(e.target.value, 'pac_ocupacao')}
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="card-body">COMORBIDADES</div>
                            <Row className="mb-3">
                                <Col md>
                                    <div className="mb-0">Doenças Pulmonares</div>

                                    <Form.Select
                                        value={form.pac_notif_asma}
                                        onChange={(e) => changeValue(e.target.value, 'pac_notif_asma')}
                                        isInvalid={!!errors.pac_notif_asma}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </Form.Select>
                                </Col>
                                <Col md>
                                    <div className="mb-0">Doenças cardíacas crônicas e/ou hipertensão</div>

                                    <Form.Select
                                        value={form.pac_notif_cardiaco}
                                        onChange={(e) => changeValue(e.target.value, 'pac_notif_cardiaco')}
                                        isInvalid={!!errors.pac_notif_cardiaco}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md>
                                    <div className="mb-0">Diabetes</div>
                                    <Form.Select
                                        value={form.pac_notif_diabetes}
                                        onChange={(e) => changeValue(e.target.value, 'pac_notif_diabetes')}
                                        isInvalid={!!errors.pac_notif_diabetes}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </Form.Select>
                                </Col>
                                <Col md>
                                    <div className="mb-0">Doenças Renais</div>

                                    <Form.Select
                                        value={form.pac_notif_renal}
                                        onChange={(e) => changeValue(e.target.value, 'pac_notif_renal')}
                                        isInvalid={!!errors.pac_notif_renal}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md>
                                    <div className="mb-0">
                                        Imunossupressão (hemodiálise, quimio/radioterapia, transplante, ou tratamento
                                        prolongado (mais de 10 dias) de corticóide)
                                    </div>

                                    <Form.Select
                                        value={form.pac_notif_imunossup}
                                        onChange={(e) => changeValue(e.target.value, 'pac_notif_imunossup')}
                                        isInvalid={!!errors.pac_notif_imunossup}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="card-body">DADOS EPIDEMIOLÓGICOS - PRIMEIROS SINTOMAS</div>
                            <Row className="mb-3">
                                <Col md>
                                    <div className="mb-0">Teve contato com caso de COVID-19?</div>

                                    <Form.Select
                                        value={form.pac_notif_contato_covid}
                                        onChange={(e) => changeValue(e.target.value, 'pac_notif_contato_covid')}
                                        isInvalid={!!errors.pac_notif_contato_covid}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </Form.Select>
                                </Col>
                                <Col md>
                                    <div className="mb-0">Febre</div>

                                    <Form.Select
                                        value={form.pac_febre}
                                        onChange={(e) => changeValue(e.target.value, 'pac_febre')}
                                        isInvalid={!!errors.pac_febre}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </Form.Select>
                                </Col>
                                {form.pac_febre === 'S' ? (
                                    <Col md>
                                        <FloatingLabel label="Temperatura" className="mb-3">
                                            <Form.Control
                                                type="text"
                                                placeholder="Temperatura"
                                                value={form.pac_febre_temp}
                                                onChange={(e) => changeValue(e.target.value, 'pac_febre_temp')}
                                            />
                                        </FloatingLabel>
                                    </Col>
                                ) : (
                                    ''
                                )}
                                <Col md>
                                    <div className="mb-0">Tosse</div>

                                    <Form.Select
                                        value={form.pac_tosse}
                                        onChange={(e) => changeValue(e.target.value, 'pac_tosse')}
                                        isInvalid={!!errors.pac_tosse}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md>
                                    <div className="mb-0">Desconforto respiratório</div>

                                    <Form.Select
                                        value={form.pac_dor_respirar}
                                        onChange={(e) => changeValue(e.target.value, 'pac_dor_respirar')}
                                        isInvalid={!!errors.pac_dor_respirar}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </Form.Select>
                                </Col>
                                <Col md>
                                    <div className="mb-0">Falta de ar</div>

                                    <Form.Select
                                        value={form.pac_falta_ar}
                                        onChange={(e) => changeValue(e.target.value, 'pac_falta_ar')}
                                        isInvalid={!!errors.pac_falta_ar}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </Form.Select>
                                </Col>
                                <Col md>
                                    <div className="mb-0">Dor de garganta</div>

                                    <Form.Select
                                        value={form.pac_dor_garganta}
                                        onChange={(e) => changeValue(e.target.value, 'pac_dor_garganta')}
                                        isInvalid={!!errors.pac_dor_garganta}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md>
                                    <div className="mb-0">Diarréia</div>

                                    <Form.Select
                                        value={form.pac_diarreia}
                                        onChange={(e) => changeValue(e.target.value, 'pac_diarreia')}
                                        isInvalid={!!errors.pac_diarreia}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </Form.Select>
                                </Col>
                                <Col md>
                                    <div className="mb-0">Diminuição/perda do olfato e/ou paladar</div>

                                    <Form.Select
                                        value={form.pac_olfato_paladar}
                                        onChange={(e) => changeValue(e.target.value, 'pac_olfato_paladar')}
                                        isInvalid={!!errors.pac_olfato_paladar}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </Form.Select>
                                </Col>
                                <Col md>
                                    <div className="mb-0">Cefaléia</div>

                                    <Form.Select
                                        value={form.pac_notif_cefaleia}
                                        onChange={(e) => changeValue(e.target.value, 'pac_notif_cefaleia')}
                                        isInvalid={!!errors.pac_notif_cefaleia}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md>
                                    <div className="mb-0">Mialgia</div>

                                    <Form.Select
                                        value={form.pac_notif_mialgia}
                                        onChange={(e) => changeValue(e.target.value, 'pac_notif_mialgia')}
                                        isInvalid={!!errors.pac_notif_mialgia}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </Form.Select>
                                </Col>
                                <Col md>
                                    <div className="mb-0">Fadiga aos mínimos esforços</div>

                                    <Form.Select
                                        value={form.pac_notif_fadiga}
                                        onChange={(e) => changeValue(e.target.value, 'pac_notif_fadiga')}
                                        isInvalid={!!errors.pac_notif_fadiga}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </Form.Select>
                                </Col>
                                <Col md>
                                    <div className="mb-0">Náusea e/ou vômito</div>

                                    <Form.Select
                                        value={form.pac_enjoo_vomito}
                                        onChange={(e) => changeValue(e.target.value, 'pac_enjoo_vomito')}
                                        isInvalid={!!errors.pac_enjoo_vomito}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md>
                                    <div className="mb-0">Congestão nasal e/ou coriza</div>

                                    <Form.Select
                                        value={form.pac_cong_nasal_coriza}
                                        onChange={(e) => changeValue(e.target.value, 'pac_cong_nasal_coriza')}
                                        isInvalid={!!errors.pac_cong_nasal_coriza}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </Form.Select>
                                </Col>
                                <Col md>
                                    <div className="mb-0">Indisposição e/ou fraqueza</div>

                                    <Form.Select
                                        value={form.pac_indisposicao_fraqueza}
                                        onChange={(e) => changeValue(e.target.value, 'pac_indisposicao_fraqueza')}
                                        isInvalid={!!errors.pac_indisposicao_fraqueza}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </Form.Select>
                                </Col>
                                <Col md>
                                    <div className="mb-0">Inapetência</div>

                                    <Form.Select
                                        value={form.pac_inapetencia}
                                        onChange={(e) => changeValue(e.target.value, 'pac_inapetencia')}
                                        isInvalid={!!errors.pac_inapetencia}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                {/*<Col md>
                                    <div className="mb-0">
                                        Data da coleta GAL
                                    </div>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        value={form.data_coleta_gal}
                                        readOnly
                                    />
                                </Col>
                                <Col md>
                                    <div className="mb-0">
                                        Sugestão de Encerramento
                                    </div>
                                    <Form.Control
                                        type="date"
                                        placeholder=""
                                        value={form.pac_sugestao_encerramento}
                                        onChange={(e) =>
                                            changeValue(
                                                e.target.value,
                                                'pac_sugestao_encerramento'
                                            )
                                        }
                                        isInvalid={
                                            !!errors.pac_sugestao_encerramento
                                        }
                                    />
                                </Col> */}
                            </Row>
                        </div>
                    </div>
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="card-body">
                                <FloatingLabel label="Observação / Resposta ao paciente">
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Observação / Resposta ao paciente"
                                        style={{ height: '100px' }}
                                        value={form.pac_notif_observacao}
                                        onChange={(e) => changeValue(e.target.value, 'pac_notif_observacao')}
                                    />
                                </FloatingLabel>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="card-body">EXAMES</div>
                            <Row className="mb-3">
                                <Col md>
                                    <div className="mb-0">IGM Resultado</div>
                                    <Form.Select
                                        value={form.pac_igm_result}
                                        onChange={(e) => changeValue(e.target.value, 'pac_igm_result')}
                                        isInvalid={!!errors.pac_igm_result}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="S">Positivo</option>
                                        <option value="N">Negativo</option>
                                        <option value="R">Não Realizado</option>
                                    </Form.Select>
                                </Col>
                                <Col md>
                                    <div className="mb-0">IGG Resultado</div>
                                    <Form.Select
                                        value={form.pac_igg_result}
                                        onChange={(e) => changeValue(e.target.value, 'pac_igg_result')}
                                        isInvalid={!!errors.pac_igg_result}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="S">Positivo</option>
                                        <option value="N">Negativo</option>
                                        <option value="R">Não Realizado</option>
                                    </Form.Select>
                                </Col>
                                <Col md>
                                    <div className="mb-0">RT-PCR</div>

                                    <Form.Select
                                        value={form.pac_rt_pcr_result}
                                        onChange={(e) => changeValue(e.target.value, 'pac_rt_pcr_result')}
                                        isInvalid={!!errors.pac_rt_pcr_result}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="D">Detectável</option>
                                        <option value="N">Não Detectável</option>
                                        <option value="R">Não Realizado</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    {/*
                <div className="card mb-3">
                    <div className="card-body">
                        <div className="card-body">EVOLUÇÃO DO CASO</div>
                        <Row className="mb-3">
                            <Col md>
                                <div className="mb-0">Situação</div>

                                <Form.Select>
                                    <option value="">Selecione</option>
                                    <option value="S">
                                        Isolamento Domiciliar
                                    </option>
                                    <option value="N">Internado</option>
                                </Form.Select>
                            </Col>
                          
                            <Col md>
                                <div className="mb-0">
                                    Leito (Em caso de internamento)
                                </div>

                                <Form.Select>
                                    <option value="">Selecione</option>
                                    <option value="S">
                                        Isolamento Domiciliar
                                    </option>
                                    <option value="N">Internado</option>
                                </Form.Select>
                            </Col>
                            <Col md>
                                <div className="mb-0">Suporte ventilatório</div>

                                <Form.Select>
                                    <option value="">Selecione</option>
                                    <option value="S">Entubado</option>
                                    <option value="N">Em uso de O2</option>
                                    <option value="N">Ar ambiente</option>
                                </Form.Select>
                            </Col>
                           
                            
                        </Row>
                    </div>
                </div>
                 */}
                    {/* <div className="card mb-3">
                    <div className="card-body">
                        <Nav
                            variant="tabs"
                            defaultActiveKey="link-0"
                            onSelect={handleSelect}
                        >
                            <Nav.Item>
                                <Nav.Link eventKey="link-0">
                                    CONTATOS REALIZADOS
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        {subForm}
                    </div>
                </div> 
                <div className="card mb-3">
                    <div className="card-body">
                        <div className="card-body">ENCERRAMENTO DO CASO</div>
                        <Row className="mb-3">
                            <Col md>
                                <div className="mb-0">
                                    Sugestão data de Encerramento
                                </div>
                                <Form.Control
                                    type="text"
                                    placeholder=""
                                    readOnly
                                />
                            </Col>
                            <Col md>
                                <FloatingLabel label="Data do encerramento">
                                    <Form.Control type="date" />
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <div className="mb-0">
                                    Motivo do encerramento
                                </div>

                                <Form.Select>
                                    <option value="">Selecione</option>
                                    <option value="S">Entubado</option>
                                    <option value="N">Em uso de O2</option>
                                    <option value="N">Ar ambiente</option>
                                </Form.Select>
                            </Col>
                        </Row>
                    </div>
                </div>
                */}
                    <Button variant="success" onClick={updatePaciente}>
                        Salvar
                    </Button>{' '}
                    {/*  <Button variant="secondary">Voltar</Button>{' '} */}
                </Form>
            </Container>
        </Container>
    );
}
