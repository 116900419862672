import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Protected from '../services/routes/protected';

//PAGES
import Index from '../pages/home/casos_confirmados';

export default function HomeRoutes(props) {
    return (
        <Routes>
            <Route
                index
                element={
                    <Protected>
                        <Index />
                    </Protected>
                }
            />
            <Route path="*" element={<h1>Página não encontrada</h1>} />
        </Routes>
    );
}
