import React, { Component } from 'react';
import { Link } from 'react-router-dom';
//import $ from 'jquery';
//import Pagination from 'react-bootstrap/Pagination';

//BOOTSTRAP REACT
import { Container } from 'react-bootstrap';
import '../style.css';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

//SERVICES
import Logged from '../../../../services/util/check';
import HttpService from '../../../../services/http/casos_confirmados/monitoramento_service';
//import Util from '../../../../../services/util/util';

//COMPONENTS
import ContatosRealizados from '../primeiro_atendimento/form_primeiro_atendimento/contatos_realizados';
//import Monitorar from './view_profile/monitorar';
import Historico from './view_profile/historico_monitoramento';
import Pagination from '../../../../components/pagination';
import CustomTable from '../../../../components/customTable';
import Loading from '../../../../components/loading';
import Error from '../../../errors/error';
import Toast from '../../../../services/util/toast';

class Monitoramento extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isError: false,
            showModal: false,

            //DADOS
            header: [],
            data: [],
            pacientes_bloqueados: [],

            //PAGINAÇÃO
            count: 0,
            page: 1,
            limit: 10,

            //FILTROS
            search: ''
        };

        this.socket = this.props.socket;

        //REQUEST
        this.getDataTable = this.getDataTable.bind(this);

        //SEARCH
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.onEnterStateSearch = this.onEnterStateSearch.bind(this);
        this.getDataTableSearch = this.getDataTableSearch.bind(this);
    }

    componentDidMount() {
        //REQUISIÇÃO DE DADOS
        this.getDataTable();
    }
    componentWillMount() {
        this.socket.on('pacientes_bloqueados', (data) => {
            this.setState({ pacientes_bloqueados: data });
        });
        this.socket.emit('getPacientes_bloqueados', (data) => {
            this.setState({ pacientes_bloqueados: data });
        });
    }

    //REQUISIÇÂO DE DADOS
    async getDataTable() {
        let data = {
            page: this.state.page,
            limit: this.state.limit,
            token: Logged.token
        };

        await HttpService.consulta(data)
            .then((response) => {
                this.setState({
                    isLoading: false,
                    count: response.data.count,
                    data: response.data.data,
                    header: response.data.header
                });
            })
            .catch((e) => {
                this.setState({
                    isLoading: false,
                    isError: true,
                    showModal: false
                });
                Toast.error(e.response.data.message);
            });
    }

    //SEARCH
    onChangeSearch(e) {
        this.setState({
            count: 0,
            page: 1,
            search: e.target.value
        });
    }
    //PESQUISA QUANDO O USUARIO PRESSIONA ENTER
    onEnterStateSearch(event) {
        if (event.key === 'Enter') {
            if (this.state.search !== '') {
                this.getDataTableSearch('OK');
                return true;
            } else {
                this.getDataTable('OK');
                return true;
            }
        }
    }
    //REQUISIÇÃO DE DADOS PARA A PESQUISA
    async getDataTableSearch(type) {
        if (type == null) {
            return false;
        }
        let data = {
            name: this.state.search,
            page: this.state.page,
            limit: this.state.limit,
            col: 'pac_nome'
        };

        await HttpService.search(data)
            .then((response) => {
                this.setState({
                    isLoading: false,
                    count: response.data.count,
                    data: response.data.data,
                    header: response.data.header
                });
            })
            .catch((e) => {
                //alert(e.response.data.message);
            });
    }

    render() {
        if (this.state.isError) {
            return <Error />;
        }
        return (
            <Container fluid className="container_content_index">
                <Container fluid className="content_index">
                    <div className="header_index">
                        <input
                            className="search_input_tabela_gal"
                            type="text"
                            placeholder="Pesquisar"
                            onKeyPress={this.onEnterStateSearch}
                            onChange={this.onChangeSearch}
                            value={this.state.search}
                        />
                    </div>

                    {this.state.isError ? (
                        <Error />
                    ) : this.state.isLoading ? (
                        <Loading />
                    ) : (
                        <CustomTable
                            id="table-to-xls"
                            header={this.state.header.map((result) => {
                                if (result.value === 'Resultado') {
                                    return (
                                        <>
                                            <th onClick={this.changeTypeResult}>{result.value}</th>
                                        </>
                                    );
                                } else {
                                    return (
                                        <>
                                            <th>{result.value}</th>
                                        </>
                                    );
                                }
                            })}
                            data={this.state.data.map((result) => {
                                let paciente_block = false;
                                let operador = '';

                                for (let i = 0; i < this.state.pacientes_bloqueados.length; i++) {
                                    if (result.id === parseInt(this.state.pacientes_bloqueados[i].id)) {
                                        operador = this.state.pacientes_bloqueados[i].operador;
                                        paciente_block = true;
                                    }
                                }

                                return (
                                    <>
                                        <tr
                                            style={
                                                paciente_block
                                                    ? {
                                                          backgroundColor: '#000000',
                                                          border: '#000000',
                                                          color: '#ffffff'
                                                      }
                                                    : {}
                                            }
                                        >
                                            {paciente_block ? (
                                                <td>{operador}</td>
                                            ) : (
                                                <td>
                                                    <button className="botoesFuncoes">
                                                        <Link
                                                            className="view"
                                                            to={`view/${result.id}`}
                                                            style={{
                                                                margin: '5px'
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faEye} />
                                                        </Link>
                                                    </button>
                                                    <button className="botoesFuncoes">
                                                        <Link
                                                            className="view"
                                                            to={`#`}
                                                            style={{
                                                                margin: '5px'
                                                            }}
                                                        >
                                                            <ContatosRealizados id={result.id} />
                                                        </Link>
                                                    </button>

                                                    <button className="botoesFuncoes">
                                                        <Link
                                                            className="view"
                                                            to={`#`}
                                                            style={{
                                                                margin: '5px'
                                                            }}
                                                        >
                                                            <Historico id={result.id} />
                                                        </Link>
                                                    </button>
                                                </td>
                                            )}

                                            {this.state.header.map((resulty) => {
                                                if (resulty.value === 'Paciente') {
                                                    return (
                                                        <>
                                                            <td>
                                                                <table>
                                                                    <tbody>
                                                                        <tr>
                                                                            <b>Nome: </b>
                                                                            {result.pac_nome}{' '}
                                                                        </tr>
                                                                        <tr>
                                                                            <b>Telefone: </b>
                                                                            {result.pac_telefone_1}{' '}
                                                                        </tr>
                                                                        <tr>
                                                                            <b>Idade: </b>
                                                                            {result.pac_idade}
                                                                        </tr>
                                                                        <tr>
                                                                            <b>Confirmado Covid-19: </b>
                                                                            {result.pac_confirmado === 'S'
                                                                                ? 'Sim'
                                                                                : 'Não'}
                                                                        </tr>
                                                                        <tr>
                                                                            <b>Início dos Sintomas: </b>
                                                                            {result.pac_data_ini_sintoma}
                                                                        </tr>
                                                                        <b>Data da liberação do exame: </b>
                                                                        {result.pac_data_liberacao_exame}
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </>
                                                    );
                                                }
                                                if (resulty.value === 'Confirmado') {
                                                    return (
                                                        <td>
                                                            <b>Monitorado: </b>
                                                            {result.monitorado}
                                                            <br></br>
                                                            <br></br>
                                                            <b>Paciente origem: </b>
                                                            <br></br>
                                                            {result.paciente_origem}
                                                        </td>
                                                    );
                                                }
                                                if (resulty.value === 'Detalhes') {
                                                    return (
                                                        <td>
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <b>Quantidade de dias:</b>{' '}
                                                                        {result.data_da_liberacao}{' '}
                                                                    </tr>
                                                                    <tr>
                                                                        <b>Sugestão de encerramento:</b>{' '}
                                                                        {result.pac_sugestao_encerramento}{' '}
                                                                    </tr>
                                                                    <tr>
                                                                        <b>Data da alteração do cadastro:</b>{' '}
                                                                        {result.pac_data_alteracao}{' '}
                                                                    </tr>
                                                                    <tr>
                                                                        <b>Responsável alteração do cadastro:</b>{' '}
                                                                        {result.usr_login}{' '}
                                                                    </tr>
                                                                    <tr>
                                                                        <b>Último monitoramento:</b>{' '}
                                                                        {result.pac_evo_dt_ult_monitora}{' '}
                                                                    </tr>
                                                                    <b>Técnico último monitoramento:</b> {result.login}{' '}
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    );
                                                }
                                                return (
                                                    <>
                                                        <td>{result[resulty.name]}</td>
                                                    </>
                                                );
                                            })}
                                        </tr>
                                    </>
                                );
                            })}
                        />
                    )}
                    <Pagination
                        className="pagination-bar"
                        currentPage={this.state.page}
                        totalCount={this.state.count}
                        pageSize={this.state.limit}
                        countItems={this.state.data.length}
                        onPageChange={(nPage) => {
                            this.setState({ page: nPage }, () => {
                                if (this.state.search !== '') {
                                    this.getDataTableSearch(true);
                                } else {
                                    this.getDataTable(true);
                                }
                            });
                        }}
                        onLimitChange={(value) => {
                            this.setState({ limit: value }, () => {
                                if (this.state.search !== '') {
                                    this.getDataTableSearch(true);
                                } else {
                                    this.getDataTable(true);
                                }
                            });
                        }}
                    />
                </Container>
            </Container>
        );
    }
}
export default Monitoramento;
