import React from 'react';
import classnames from 'classnames';

import { usePagination, DOTS } from '../hooks/usePagination';
import './pagination.scss';
const Pagination = (props) => {
    const {
        onPageChange,
        onLimitChange,
        totalCount,
        countItems,
        pageSize,
        siblingCount = 3,
        currentPage,
        className
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    const currentCountItem = () => {
        if (currentPage === lastPage) {
            return pageSize * currentPage - pageSize + countItems;
        } else {
            return pageSize * currentPage;
        }
    };

    const changeLimit = (e) => {
        onLimitChange(e);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <div className="footer_tabela_gal">
            <div className="footer_text_tabela_gal">
                {pageSize * currentPage + 1 - pageSize} - {currentCountItem()} de {totalCount}
            </div>

            <ul
                className={classnames('pagination-container', {
                    [className]: className
                })}
            >
                <li
                    className={classnames('pagination-item', {
                        disabled: currentPage === 1
                    })}
                    onClick={onPrevious}
                >
                    <div className="arrow left" />
                </li>
                {paginationRange.map((pageNumber) => {
                    if (pageNumber === DOTS) {
                        return <li className="pagination-item dots">&#8230;</li>;
                    }
                    return (
                        <li
                            className={classnames('pagination-item', {
                                selected: pageNumber === currentPage
                            })}
                            onClick={() => onPageChange(pageNumber)}
                        >
                            {pageNumber}
                        </li>
                    );
                })}
                <li
                    className={classnames('pagination-item', {
                        disabled: currentPage === lastPage
                    })}
                    onClick={onNext}
                >
                    <div className="arrow right" />
                </li>
                <select
                    style={{ margin: '5px', marginBottom: '5px' }}
                    value={pageSize}
                    onChange={(e) => changeLimit(e.target.value)}
                >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                </select>
            </ul>
        </div>
    );
};

export default Pagination;
