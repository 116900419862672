import React, { useState } from 'react';
//import socket from '../../../../../../services/socket/socketio';

//BOOTSTRAP REACT
import { Container, Button, Modal, Form } from 'react-bootstrap';
//CSS
//import '../../../../../assets/css/pages/menus/tabelas-do-sistema/index.css';

//SERVICES
import HttpService from '../../../../../../services/http/casos_confirmados/primeiro_atendimento_service';

//COMPONENTS
import CustomTable from '../../../../../../components/customTable';
import Logged from '../../../../../../services/util/check';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPhoneSlash, faFloppyDisk, faTrash } from '@fortawesome/free-solid-svg-icons';
/* import { useSocketIo } from '../../../../../../hooks/useSocket'; */

export default function ContatosRealizados(props) {
    const id = props.id;

    /*  const [lastPong, setLastPong] = useState(null); */
    const [data, setData] = useState([]);
    const [contatoText, setContatoText] = useState('');
    const [mensagemText, setMensagemText] = useState('');
    const [header, setHeader] = useState([
        {
            name: 'contato_data',
            value: 'Data do Contato'
        },
        {
            name: 'mensagem_texto',
            value: 'Informação'
        },
        {
            name: 'contato_texto',
            value: 'Comunicação'
        },
        {
            name: 'usr_login',
            value: 'Técnico'
        }
    ]);
    const [isAdd, setIsAdd] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [data_contato, setData_contato] = useState(new Date());

    const formatButton = props.howButton;

    /*  useEffect(() => {
        setInterval(() => {
            socket.emit('ping');
        }, 500);

        socket.on('pong', (data) => {
            setLastPong(data);
        });

        return () => {
            socket.off('pong');
            socket.close();
        };
    }, []); */

    function f_data_contato() {
        setData_contato(new Date());
    }

    //REQUISICAO DE DADOS
    async function getContatos() {
        await HttpService.consultaContatos({
            id: parseInt(id),
            page: 1,
            limit: 20
        })
            .then(async (response) => {
                setHeader(response.data.header);

                var res = response.data.data;

                for (let i = 0; i < res.length; i++) {
                    const dataDB = response.data.data[i].contato_data; //.toLocaleString();

                    const dataFormat = new Date(dataDB);

                    const dataFormatBr = dataFormat.toLocaleDateString('pt-BR');
                    const horaFormatBr = dataFormat.toLocaleTimeString('pt-BR');

                    res[i].contato_data = dataFormatBr + ' ' + horaFormatBr;
                }

                setData(res);
            })
            .catch((e) => {});
    }

    function handleShow() {
        setData([]);
        setIsAdd(false);
        getContatos();
        setShowModal(true);
    }
    function handleClose() {
        setShowModal(false);
    }

    //NOVO REGISTRO NA TABELA
    function novoRegistro() {
        f_data_contato();
        if (!isAdd) {
            const dataAdd = data;

            dataAdd.push({
                id: 'new',
                contato_data: (
                    <>
                        <div>
                            {data_contato.toLocaleString('pt-BR', {
                                hour12: false
                            })}
                        </div>
                    </>
                ),
                mensagem_texto: (
                    <>
                        <div>
                            <Form.Select onChange={(e) => setMensagemText(e.target.value)}>
                                <option value="">Selecione</option>
                                <option value="3">Atendeu e informou está ocupado(a)</option>
                                <option value="2">Atendeu e recusou monitoramento</option>
                                <option value="4">Encaminhado para avaliação médica</option>
                                <option value="12">Informado óbito</option>
                                <option value="7">Ligação atendida informou não conhecer paciente</option>
                                <option value="5">Ligação encaminhada para caixa postal</option>
                                <option value="1">Ligação não atendida - 3 tentativas</option>
                                <option value="6">Ligação não completa - 3 tentativas</option>
                                <option value="9">Sem registro de contato / número incorreto</option>
                                <option value="10">Tempo de isolamento {'>'} sete dias</option>
                                <option value="11">Teste positivo do tipo ANTÍGENO</option>
                                <option value="13">Ligação atendida; informado "ALTA" e encerrado</option>
                            </Form.Select>
                        </div>
                    </>
                ),
                contato_texto: (
                    <>
                        <div>
                            <Form.Control
                                type="text"
                                placeholder="Comunicação"
                                onChange={(e) => setContatoText(e.target.value)}
                            />
                        </div>
                    </>
                ),
                usr_login: (
                    <>
                        <div>{Logged.userName}</div>
                    </>
                )
            });

            setData(dataAdd);
            setIsAdd(true);
            return;
        }
    }
    function cancelarMudancas(e) {
        setContatoText('');
        setMensagemText('');
        const removeNovoRegistro = data;
        removeNovoRegistro.pop();
        setIsAdd(false);
        setData(removeNovoRegistro);
    }

    //REQUISICAO SALVAR REGISTRO
    async function saveItem(filter) {
        if (mensagemText === '') {
            return false;
        }

        //SALVA A DATA COM -3 HORAS PARA AJUSTAR COM O HORARIO DO BRASIL
        const dataEnv = data_contato.setTime(data_contato.getTime() - 3 * 60 * 60 * 1000);
        const data = {
            id: id,
            data_contato: new Date(dataEnv),
            mensagem_texto: mensagemText,
            comunicacao: contatoText
        };

        await HttpService.insertContatos(data)
            .then((response) => {
                setData([]);
                setIsAdd(false);
                getContatos();
            })
            .catch((e) => {
                alert(e.response.data.message);
            });
    }

    return (
        <>
            {formatButton === true ? (
                <>
                    <Button variant="primary" onClick={handleShow}>
                        <FontAwesomeIcon icon={faPhoneSlash} /> Contatos Realizados
                    </Button>{' '}
                </>
            ) : (
                <>
                    <FontAwesomeIcon onClick={handleShow} icon={faPhoneSlash} />
                </>
            )}

            <Modal show={showModal} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Contatos Realizados</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Container fluid className="container_content_index">
                        <Container fluid className="content_index full">
                            {/*     <div>
                                <p>Last pong: {lastPong || '-'}</p>
                            </div> */}

                            <div
                                className="header_tabela_gal"
                                style={{
                                    display: 'flex',
                                    height: '50px',
                                    width: '100%'
                                }}
                            >
                                <button
                                    className="btn_tabela_gal"
                                    style={{
                                        marginTop: '10px'
                                    }}
                                    onClick={novoRegistro}
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                    <label htmlFor=""> Novo</label>
                                </button>
                            </div>

                            <div className="content_tabela_gal">
                                <div className="d_table_tabela_gal">
                                    <CustomTable
                                        id="table-to-xls"
                                        header={header.map((result) => {
                                            return (
                                                <>
                                                    <th key={`header${result.id}`}>{result.value}</th>
                                                </>
                                            );
                                        })}
                                        data={data.map((result) => {
                                            return (
                                                <>
                                                    <tr key={`linha${result.id}`}>
                                                        {result.id ? (
                                                            <td>
                                                                <button
                                                                    style={{
                                                                        backgroundColor: 'transparent',
                                                                        border: 'none',

                                                                        fontSize: '18px',
                                                                        color: 'red'
                                                                    }}
                                                                    onClick={cancelarMudancas}
                                                                >
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </button>
                                                                <button
                                                                    style={{
                                                                        backgroundColor: 'transparent',
                                                                        border: 'none',

                                                                        fontSize: '18px',
                                                                        color: 'blue',
                                                                        marginLeft: '10px'
                                                                    }}
                                                                    onClick={saveItem}
                                                                >
                                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                                </button>
                                                            </td>
                                                        ) : (
                                                            <td></td>
                                                        )}

                                                        {header.map((resulty) => {
                                                            var row = (
                                                                <>
                                                                    <td key={`celula${result.id}`}>
                                                                        {result[resulty.name]}
                                                                    </td>
                                                                </>
                                                            );

                                                            return row;
                                                        })}
                                                    </tr>
                                                </>
                                            );
                                        })}
                                    />
                                </div>
                            </div>
                        </Container>{' '}
                    </Container>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
