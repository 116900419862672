import React, { Component } from 'react';

//BOOTSTRAP REACT
import { Container } from 'react-bootstrap';

//CSS
import './style.css';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

//COMPONENTS
import CustomTable from '../../../../components/customTable';
import Pagination from '../../../../components/pagination';
import Loading from '../../../../components/loading';
import Error from '../../../errors/error';

//SERVICES
import Logged from '../../../../services/util/check';
import HttpService from '../../../../services/http/tabelas_do_sistema/motivo_finalizacao_monitoramento';
import Toast from '../../../../services/util/toast';

class TabelaMotivoFinalizacaoMonitoramento extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isError: false,
            isAdd: false,

            //DADOS
            header: [{}],
            data: [{}],
            add: '',

            //PAGINAÇÃO
            count: 0,
            page: 1,
            limit: 20,

            //FILTROS
            search: '',
            filter: null
        };
        this.getDataTable = this.getDataTable.bind(this);
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.onEnterStateSearch = this.onEnterStateSearch.bind(this);
        this.getDataTableSearch = this.getDataTableSearch.bind(this);
        this.changeTypeResult = this.changeTypeResult.bind(this);
        this.novoRegistro = this.novoRegistro.bind(this);
        this.cancelarMudancas = this.cancelarMudancas.bind(this);
        this.onChangeAdd = this.onChangeAdd.bind(this);
        this.SaveItem = this.SaveItem.bind(this);
        this.saveEditItem = this.saveEditItem.bind(this);
        this.editarItem = this.editarItem.bind(this);
        this.deletarItem = this.deletarItem.bind(this);
    }

    componentDidMount() {
        //VERIFICA SE O TOKEN DO USUARIO ESTÁ VÁLIDO
        Logged.checkLogged();

        //REQUISIÇÃO DE DADOS
        this.getDataTable({});
    }

    //REQUISIÇÂO DE DADOS
    async getDataTable(filter) {
        if (filter == null) {
            return false;
        }

        let data = {
            token: Logged.token
        };

        await HttpService.listAll(data)
            .then((response) => {
                this.setState({
                    isLoading: false,
                    count: response.data.count,
                    data: response.data.data,
                    header: response.data.header
                });
            })
            .catch((e) => {
                this.setState({
                    isLoading: false,
                    isError: true
                });
                Toast.error(e.response.data.message);
            });
    }

    //SEARCH
    onChangeSearch(e) {
        this.setState({
            count: 0,
            page: 1,
            search: e.target.value
        });
    }

    //PESQUISA QUANDO O USUARIO PRESSIONA ENTER
    onEnterStateSearch(event) {
        if (event.key === 'Enter') {
            if (this.state.search !== '') {
                this.getDataTableSearch('OK');
                return true;
            } else {
                this.getDataTable('');
                return true;
            }
        }
    }
    //REQUISIÇÃO DE DADOS PARA A PESQUISA
    async getDataTableSearch(type) {
        if (type == null) {
            return false;
        }
        let data = {
            search: this.state.search,
            page: this.state.page,
            limit: this.state.limit,
            token: Logged.token
        };

        await HttpService.search(data)
            .then((response) => {
                this.setState({
                    isLoading: false,
                    count: response.data.count,
                    data: response.data.data,
                    header: response.data.header,
                    add: '',
                    isAdd: false
                });
            })
            .catch((e) => {
                Toast.error(e.response.data.message);
            });
    }

    //ORDENAR PELO RESULTADO
    async changeTypeResult() {
        if (this.state.resultadoNegativo === false) {
            this.setState({
                isLoading: true,
                count: 0,
                page: 1,
                resultadoNegativo: true,
                add: '',
                isAdd: false
            });
            await this.getDataTable(null);
        } else {
            this.setState({
                isLoading: true,
                count: 0,
                page: 1,
                resultadoNegativo: false,
                add: '',
                isAdd: false
            });
            await this.getDataTable(null);
        }
        await this.getDataTable(this.state.resultadoNegativo);
        this.forceUpdate();
    }

    //NOVO REGISTRO NA TABELA
    novoRegistro() {
        if (!this.state.isAdd) {
            var dados = this.state.data;
            dados.push({
                id: (
                    <>
                        <button className="btnSalvarFuncoes" onClick={this.SaveItem}>
                            SALVAR
                        </button>
                        <button className="btnSalvarFuncoes" onClick={this.cancelarMudancas}>
                            CANCELAR
                        </button>
                    </>
                ),
                motivo: <input type="text" onChange={this.onChangeAdd} />
            });
            this.setState({ data: dados, isAdd: true });
        }
    }
    cancelarMudancas(e) {
        this.setState({
            add: '',
            isAdd: false
        });
        this.getDataTable({});
    }
    //SALVAR DADOS DO NOVO REGISTRO NO ESTADO
    onChangeAdd(e) {
        this.setState({
            add: e.target.value
        });
    }
    //REQUISICAO SALVAR REGISTRO
    async SaveItem(filter) {
        if (this.state.add === '') {
            return false;
        }

        let data = {
            value: this.state.add,
            token: Logged.token
        };

        await HttpService.insert(data)
            .then((response) => {
                this.getDataTable('');
                this.setState({
                    add: '',
                    isAdd: false
                });
            })
            .catch((e) => {
                Toast.error(e.response.data.message);
            });
    }

    //EDITAR ITEM
    editarItem(e) {
        if (!this.state.isAdd) {
            const dados = this.state.data;
            for (let i = 0; i < dados.length; i++) {
                if (dados[i].motivo === e) {
                    dados[i] = {
                        id: (
                            <>
                                <button
                                    className="btnSalvarFuncoes"
                                    onClick={() => this.saveEditItem(dados[i].motivo.props.placeholder)}
                                >
                                    SALVAR
                                </button>
                                <button className="btnSalvarFuncoes" onClick={this.cancelarMudancas}>
                                    CANCELAR
                                </button>
                            </>
                        ),
                        motivo: <input type="text" onChange={this.onChangeAdd} placeholder={dados[i].motivo} />
                    };
                    this.setState({
                        isAdd: true,
                        data: dados
                    });
                    break;
                } else {
                    continue;
                }
            }
        }
    }
    async saveEditItem(e) {
        if (this.state.add === '') {
            return false;
        }
        let data = {
            value: e,
            newValue: this.state.add,
            token: Logged.token
        };

        await HttpService.update(data)
            .then((response) => {
                this.getDataTable('');
                this.setState({
                    add: '',
                    isAdd: false
                });
            })
            .catch((e) => {
                Toast.error(e.response.data.message);
            });
    }

    //CONFIRMAR DELETE ITEM
    confirmeDelete() {
        //ABRIR MODAL DE CONFIRMAÇÂO
    }
    //DELETAR REGISTRO
    async deletarItem(e) {
        let data = {
            value: e,
            token: Logged.token
        };

        await HttpService.delete(data)
            .then((response) => {
                return true;
            })
            .catch((e) => {
                return false;
            });

        this.getDataTable('');
        this.setState({
            add: '',
            isAdd: false
        });
    }

    render() {
        if (this.state.isError) {
            return (
                <>
                    <Error />
                </>
            );
        }

        if (this.state.isLoading) {
            return (
                <>
                    <Loading />
                </>
            );
        }

        return (
            <Container fluid className="container_content_index">
                <Container fluid className="content_index">
                    <div className="header_index">
                        <button className="btn_tabela_gal" onClick={this.novoRegistro}>
                            <FontAwesomeIcon icon={faPlus} />
                            <label htmlFor=""> Novo</label>
                        </button>
                        <input
                            className="search_input_tabela_gal"
                            type="text"
                            placeholder="Pesquisar"
                            onKeyPress={this.onEnterStateSearch}
                            onChange={this.onChangeSearch}
                            value={this.state.search}
                        />
                    </div>

                    <CustomTable
                        id="table-to-xls"
                        header={this.state.header.map((result) => {
                            return (
                                <>
                                    <th key={`header${result.id}`}>{result.value}</th>
                                </>
                            );
                        })}
                        data={this.state.data.map((result) => {
                            return (
                                <>
                                    <tr key={`linha${result.id}`}>
                                        <td>
                                            <div className="funcoes_tabela">
                                                <button
                                                    className="botoesFuncoes"
                                                    onClick={() => {
                                                        this.editarItem(result.motivo);
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                                <button
                                                    className="botoesFuncoes"
                                                    onClick={() => this.deletarItem(result.motivo)}
                                                >
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </div>
                                        </td>
                                        {this.state.header.map((resulty) => {
                                            return (
                                                <>
                                                    <td key={`celula${result.id}`}>{result[resulty.name]}</td>
                                                </>
                                            );
                                        })}
                                    </tr>
                                </>
                            );
                        })}
                    />
                    <Pagination
                        className="pagination-bar"
                        currentPage={this.state.page}
                        totalCount={this.state.count}
                        pageSize={this.state.limit}
                        countItems={this.state.data.length}
                        onPageChange={(nPage) => {
                            this.setState({ page: nPage }, () => {
                                if (this.state.search !== '') {
                                    this.getDataTableSearch(true);
                                } else {
                                    this.getDataTable(true);
                                }
                            });
                        }}
                        onLimitChange={(value) => {
                            this.setState({ limit: value }, () => {
                                if (this.state.search !== '') {
                                    this.getDataTableSearch(true);
                                } else {
                                    this.getDataTable(true);
                                }
                            });
                        }}
                    />
                </Container>
            </Container>
        );
    }
}
export default TabelaMotivoFinalizacaoMonitoramento;
