import React from 'react';
// import { ToastContainer } from 'react-toastify';

//BOOTSTRAP REACT
import { Container } from 'react-bootstrap';

import '../../assets/css/components/content.css';
import 'react-toastify/dist/ReactToastify.css';

export default function Content(props) {
    return (
        <>
            <Container fluid id="container_content" className="container_content">
                {/* <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                /> */}
                {props.children}
            </Container>
        </>
    );
}
