import axios from 'axios';
import { API_BASE_URL } from '../../util/constants';

class TabelaMotivoFinalizacaoMonitoramento {
    listAll(data) {
        return axios.post(
            `${API_BASE_URL}/motivoFinalizarMonitoramento/selectMotivoFinalizarMonitoramento`,
            {},
            {
                headers: {
                    'access-token': localStorage.getItem('access-token')
                }
            }
        );
    }
    search(data) {
        return axios.post(
            `${API_BASE_URL}/motivoFinalizarMonitoramento/selectMotivoFinalizarMonitoramentoByName`,
            {
                name: data.search
            },
            {
                headers: {
                    'access-token': localStorage.getItem('access-token')
                }
            }
        );
    }
    insert(data) {
        return axios.post(
            `${API_BASE_URL}/motivoFinalizarMonitoramento/insertMotivoFinalizarMonitoramento`,
            {
                value: data.value
            },
            {
                headers: {
                    'access-token': localStorage.getItem('access-token')
                }
            }
        );
    }
    update(data) {
        return axios.post(
            `${API_BASE_URL}/motivoFinalizarMonitoramento/editMotivoFinalizarMonitoramento`,
            {
                value: data.value,
                newValue: data.newValue
            },
            {
                headers: {
                    'access-token': localStorage.getItem('access-token')
                }
            }
        );
    }
    delete(data) {
        return axios.post(
            `${API_BASE_URL}/motivoFinalizarMonitoramento/deleteMotivoFinalizarMonitoramento`,
            {
                value: data.value
            },
            {
                headers: {
                    'access-token': localStorage.getItem('access-token')
                }
            }
        );
    }
}
export default new TabelaMotivoFinalizacaoMonitoramento();
