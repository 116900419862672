import axios from 'axios';
import { API_BASE_URL } from '../../util/constants';

class CasosConfirmados {
    consulta(data) {
        return axios.post(
            `${API_BASE_URL}/monitoramento/select`,
            {
                page: data.page,
                limit: data.limit
            },
            {
                headers: {
                    'access-token': localStorage.getItem('access-token')
                }
            }
        );
    }
    getById(data, token) {
        return axios.post(
            `${API_BASE_URL}/primeiroAtendimento/getById`,
            {
                id: data.id
            },
            {
                headers: {
                    'access-token': localStorage.getItem('access-token')
                }
            }
        );
    }
    search(data, token) {
        return axios.post(`${API_BASE_URL}/monitoramento/searchByName`, data, {
            headers: {
                'access-token': localStorage.getItem('access-token')
            }
        });
    }

    consultaHistoricoMonitoramento(data, token) {
        return axios.post(`${API_BASE_URL}/monitoramento/selectHistoricoMonitoramento`, data, {
            headers: {
                'access-token': localStorage.getItem('access-token')
            }
        });
    }

    insertMonitoramento(data, token) {
        return axios.post(`${API_BASE_URL}/monitoramento/insertMonitoramento`, data, {
            headers: {
                'access-token': localStorage.getItem('access-token')
            }
        });
    }
}
export default new CasosConfirmados();
