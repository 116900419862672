import React, { Component } from 'react';
import { Link } from 'react-router-dom';

//BOOTSTRAP REACT
import { Container, Button } from 'react-bootstrap';
import '../style.css';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';

//SERVICES
import HttpService from '../../../../services/http/casos_confirmados/primeiro_atendimento_service';
import Toast from '../../../../services/util/toast';

//COMPONENTS
import CadastroSimplificado from './cadastro_simplificado';
import ContatosRealizados from './form_primeiro_atendimento/contatos_realizados';
import Pagination from '../../../../components/pagination';
import CustomTable from '../../../../components/customTable';
import Loading from '../../../../components/loading';
import Error from '../../../errors/error';
import ImportarCadastros from './importar_cadastros';

class PrimeiroAtendimento extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isError: false,
            showModal: false,

            //DADOS
            header: [],
            data: [],
            pacientes_bloqueados: [],

            //PAGINAÇÃO
            count: 0,
            page: 1,
            limit: 10,

            //FILTROS
            search: ''
        };

        this.socket = this.props.socket;

        //REQUEST
        this.getDataTable = this.getDataTable.bind(this);

        //SEARCH
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.onEnterStateSearch = this.onEnterStateSearch.bind(this);
        this.getDataTableSearch = this.getDataTableSearch.bind(this);
    }

    componentDidMount() {
        this.getDataTable(true);
    }
    componentWillMount() {
        this.socket.on('pacientes_bloqueados', (data) => {
            this.setState({ pacientes_bloqueados: data });
        });
        this.socket.emit('getPacientes_bloqueados', (data) => {
            this.setState({ pacientes_bloqueados: data });
        });
    }

    //REQUISIÇÂO DE DADOS
    async getDataTable(verify) {
        if (verify == null) {
            return false;
        }
        let data = {
            page: this.state.page,
            limit: this.state.limit
        };

        await HttpService.consulta(data)
            .then((response) => {
                this.setState({
                    count: response.data.count,
                    data: response.data.data,
                    header: response.data.header,
                    isLoading: false
                });
            })
            .catch((e) => {
                this.setState({
                    isError: true,
                    showModal: false,
                    isLoading: false
                });
                Toast.error(e.response.data.message);
            });
    }

    //SEARCH
    onChangeSearch(e) {
        this.setState({
            count: 0,
            page: 1,
            search: e.target.value
        });
    }
    //PESQUISA QUANDO O USUARIO PRESSIONA ENTER
    onEnterStateSearch(event) {
        if (event.key === 'Enter') {
            if (this.state.search !== '') {
                this.getDataTableSearch('OK');
                return true;
            } else {
                this.getDataTable('OK');
                return true;
            }
        }
    }
    //REQUISIÇÃO DE DADOS PARA A PESQUISA
    async getDataTableSearch(type) {
        if (type == null) {
            return false;
        }
        let data = {
            name: this.state.search,
            page: this.state.page,
            limit: this.state.limit
        };

        await HttpService.search(data)
            .then((response) => {
                this.setState({
                    isLoading: false,
                    count: response.data.count,
                    data: response.data.data,
                    header: response.data.header
                });
            })
            .catch((e) => {
                //alert(e.response.data.message);
            });
    }

    render() {
        var key = 0;

        if (this.state.isError) {
            return <Error />;
        }
        return (
            <Container fluid className="container_content_index">
                <Container fluid className="content_index">
                    <div className="header_index">
                        <Button variant="primary">
                            <Link
                                className="view"
                                style={{
                                    background: 'transparent',
                                    color: 'white',
                                    textDecoration: 'none'
                                }}
                                to={`cadastro`}
                            >
                                Novo Cadastro
                            </Link>
                        </Button>
                        <div style={{ marginLeft: '5px' }}></div>
                        <CadastroSimplificado getDataTable={this.getDataTable} />
                        <div style={{ marginLeft: '5px' }}></div>
                        <ImportarCadastros getDataTable={this.getDataTable} />
                        <input
                            className="search_input_tabela_gal"
                            type="text"
                            placeholder="Pesquisar"
                            onKeyPress={this.onEnterStateSearch}
                            onChange={this.onChangeSearch}
                            value={this.state.search}
                        />
                    </div>

                    {this.state.isError ? (
                        <Error />
                    ) : this.state.isLoading ? (
                        <Loading />
                    ) : (
                        <CustomTable
                            id="table-to-xls"
                            header={this.state.header.map((result) => {
                                return <th key={(key += 1)}>{result.value}</th>;
                            })}
                            data={this.state.data.map((result) => {
                                let paciente_block = false;
                                let operador = '';

                                for (let i = 0; i < this.state.pacientes_bloqueados.length; i++) {
                                    if (result.id === parseInt(this.state.pacientes_bloqueados[i].id)) {
                                        operador = this.state.pacientes_bloqueados[i].operador;
                                        paciente_block = true;
                                    }
                                }
                                return (
                                    <tr
                                        style={
                                            paciente_block
                                                ? {
                                                      backgroundColor: '#000000',
                                                      border: '#000000',
                                                      color: '#ffffff'
                                                  }
                                                : {}
                                        }
                                        key={(key += 1)}
                                    >
                                        {paciente_block ? (
                                            <td>{operador}</td>
                                        ) : (
                                            <td>
                                                <button className="botoesFuncoes">
                                                    <Link className="view" to={`view/${result.id}`}>
                                                        <FontAwesomeIcon icon={faPencil} />
                                                    </Link>
                                                    <div
                                                        style={{
                                                            marginLeft: '5px'
                                                        }}
                                                    ></div>
                                                    <Link className="view" to={`#`}>
                                                        <ContatosRealizados id={result.id} />
                                                    </Link>
                                                </button>
                                            </td>
                                        )}
                                        {this.state.header.map((resulty) => {
                                            return (
                                                <>
                                                    <td>{result[resulty.name]}</td>
                                                </>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        />
                    )}

                    <Pagination
                        className="pagination-bar"
                        currentPage={this.state.page}
                        totalCount={this.state.count}
                        pageSize={this.state.limit}
                        countItems={this.state.data.length}
                        onPageChange={(nPage) => {
                            this.setState({ page: nPage }, () => {
                                if (this.state.search !== '') {
                                    this.getDataTableSearch(true);
                                } else {
                                    this.getDataTable(true);
                                }
                            });
                        }}
                        onLimitChange={(value) => {
                            this.setState({ limit: value }, () => {
                                if (this.state.search !== '') {
                                    this.getDataTableSearch(true);
                                } else {
                                    this.getDataTable(true);
                                }
                            });
                        }}
                    />
                </Container>
            </Container>
        );
    }
}
export default PrimeiroAtendimento;
